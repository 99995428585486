import { Grid, Typography } from "@material-ui/core"
import * as Adm from "@adm"
import * as Yup from "yup"
import React, { useState } from "react"
import { Formik } from "formik"
import _ from "lodash"
import { StyledForm } from "./style"
import * as constantsAPI from "@constants"

import {
  useStyles,
  ProDialog,
  StyledMainWrapper,
  StyledOverAllMainWrapper,
  StyledSideWrapper,
  StyledSideRightWrapper,
} from "./style"
import {
  renderModalHeader,
  renderProductCard,
  renderActionButton,
} from "./renderProps"
import {
  doDataInitialValues,
  doValidationSchema,
  doRenderList,
} from "./fixture"

function ProductAdjustMapping(props) {
  const classes = useStyles()
  const {
    productIndex = "",
    editModel,
    setAnyChanges,
    productOpen,
    productListObj,
    packageList = [],
    handleScroll = () => "",
    editData,
    onUpdateSave = () => "",
    onUpdateSubmit = () => "",
    isStorage = false,
    viewPage = "edit",
    editImageURL = "",
    editProductName = "",
    editItemTitle = "",
    getPackageRawData = [],
    getStorageIncrememt = [],
    getStorageDecrement = []
  } = props
  const [getIsEdit, setIsEdit] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("")
  let textError = "value must be greater than or equal to 0"
  const savebuttonAttrs = {
    variant: "contained",
    type: "submit",
    color: "primary",
  }
  const handleDialogClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }
  const handleKeyPress = (e, type) => {
    if (type === "number") {
      if (e.which === 32) {
        e.preventDefault()
      }
    } else {
      if (e.which === 32 && e.target.value === "") {
        e.preventDefault()
      }
    }
  }
  const handleWhiteSpace = (e) => {
    if (e.which === 32) {
      e.preventDefault()
    }
  }
  const onGlobalUpdate = () => {
    setAnyChanges(true)
  }

  const initialValues = doDataInitialValues(editData, isStorage, viewPage)

  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault()
  }

  const onSave = async (values, props) => {
    onUpdateSave({ values, setAlert, setAlertType, setAlertMessage, setIsEdit })
  }

  const onSubmitHandler = async (values, { resetForm }) => {
    onUpdateSubmit({
      values,
      setAlert,
      setAlertType,
      setAlertMessage,
      setIsEdit,
    })
  }

  let isCheckTax =
    _.upperCase(localStorage.getItem("taxProvider")) === _.upperCase("manual")

  return (
    <React.Fragment>
      <ProDialog maxWidth="sm" aria-labelledby="form-dialog-title">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={Yup.object().shape(
            doValidationSchema(
              {
                isTax: isCheckTax,
              },
              isStorage,
              viewPage
            )
          )}
          onSubmit={editModel ? onSave : onSubmitHandler}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            touched,
            values,
            setFieldValue,
            resetForm,
            handleSubmit,
          }) => {
            const getTaxPrice = (e, control) => {
              var offerPrice = values?.offerPrice || 0
              var taxRate = values?.taxRate || 0
              if (control === "offerPrice") {
                offerPrice = e?.target?.value || 0
              } else {
                taxRate = e?.target?.value || 0
              }
              var taxPrice = Number(
                (parseFloat(offerPrice) * parseFloat(taxRate)) / 100
              ).toFixed(2)
              setFieldValue("taxValue", taxPrice)
            }

            const getPrepopuplate = (e) => {
              setFieldValue("offerPrice", e?.target?.value)
              var offerPrice = e?.target?.value || 0
              var taxRate = values?.taxRate || 0
              var taxPrice = Number(
                (parseFloat(offerPrice) * parseFloat(taxRate)) / 100
              ).toFixed(2)
              setFieldValue("taxValue", taxPrice)
            }

            const handlePackageType = (e, val) => {
              setFieldValue("package_type", val)
            }
            const handleAdjustmentType = (e, val, cbk = () => "") => {
              setFieldValue("adjustment_type", val)
              setTimeout(() => {
                cbk()
              }, 100)
            }
            const handleAdjustmentReason = (e, val) => {
              setFieldValue("adjustment_reason", val)
            }

            const { productName } = values

            let selectedProductItem = editData

            const formProps = {
              setFieldValue,
              getPackageRawData,
              getIsEdit,
              setIsEdit,
              getTaxPrice,
              getPrepopuplate,
              editModel,
              classes,
              values,
              touched,
              errors,
              handleBlur,
              handleWhiteSpace,
              handleKeyPress,
              handleChange,
              onGlobalUpdate,
              isCheckTax,
              productListObj,
              handlePackageType,
              handleAdjustmentType,
              handleAdjustmentReason,
              productOpen,
              handleDialogClick,
              handleScroll,
              packageList,
              editImageURL,
              editProductName,
              editItemTitle,
              isStorage,
              getStorageIncrememt,
              getStorageDecrement
            }

            let dataRender = doRenderList(isStorage, viewPage)
            return (
              <StyledForm onSubmit={handleSubmit}>
                {renderModalHeader({
                  title: editData?.product_title || editData?.title,
                  renderChild: () => {
                    return (
                      <>
                        {renderActionButton({
                          getIsEdit,
                          setIsEdit,
                          parentProps: props,
                          resetForm,
                          savebuttonAttrs,
                          handleSubmit,
                          editModel,
                        })}
                      </>
                    )
                  },
                })}
                <StyledOverAllMainWrapper
                  className={isStorage ? "" : "cls-store"}
                >
                  <StyledSideWrapper>
                    {renderProductCard({
                      ...formProps,
                      product: selectedProductItem,
                      onRemove: () => "",
                    })}
                  </StyledSideWrapper>
                  <StyledSideRightWrapper>
                    {/* <Typography
                      variant="h3"
                      style={{ padding: 20, paddingBottom: 0 }}
                    >{`Product Adjustments`}</Typography> */}
                    <StyledMainWrapper>
                      {dataRender.map((render, index) => {
                        return render(formProps)
                      })}
                    </StyledMainWrapper>
                  </StyledSideRightWrapper>
                </StyledOverAllMainWrapper>
              </StyledForm>
            )
          }}
        </Formik>
      </ProDialog>
      {alert ? (
        <Adm.SnackBar open={true} message={alertMessage} type={alertType} />
      ) : null}
    </React.Fragment>
  )
}

export default ProductAdjustMapping
export { ProductAdjustMapping }
