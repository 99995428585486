import React, { useEffect, useState } from "react"
import styled from "styled-components/macro"
import * as Yup from "yup"
import { Formik } from "formik"
import {
  IconButton,
  TextField as MuiTextField,
  Grid,
  Card,
  Typography,
  Divider,
  Tooltip,
  Checkbox,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { spacing } from "@material-ui/system"
import {
  CheckCircleIcon,
  Hash,
  Phone,
  MapPin,
  Mail,
  Edit,
  Slash,
} from "@iconsGallery"
import * as Utils from "@utils"
import _, { functions, isUndefined, keys } from "lodash"
import * as ServiceAPI from "@services"
import * as Features from "@features"
import { OperatingHours } from "@features"
import * as Adm from "@adm"
import { useTranslation } from "react-i18next"
import * as constantsAPI from "@constants"
import { ConfirmationDialog, EditLocationHierarchy } from "@features"
import { InfoOutlined } from "@material-ui/icons";
import userApi from '../../services/user/api' //../../../services/user/api
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { object } from "@storybook/addon-knobs";
import { operatingHrs } from "./fixture"

const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}

const FormMainWrapper = styled.form`
  @media (min-width: 1024px) and (max-width: 1540px) {
    .cls-location-name-wrapper {
      border: none;
    }
    .MuiGrid-grid-lg-3 {
      max-width: 25% !important;
      flex-basis: 50% !important;
    }
  }
`
const TextField = styled(MuiTextField)(spacing)
const useStyles = makeStyles((theme) => ({
  root: { display: "flex", flexWrap: "wrap" },
  margin: { margin: theme.spacing(1) },
  withoutLabel: { marginTop: theme.spacing(3) },
  phonefield: {
    "& .react-tel-input": {
      width: "250px",
    },
  },
  textField: {
    marginBottom: "5px",
    marginLeft: "15px",
    width: "90%",
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "var(--primaryColor)",
      },
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "var(--primaryColor)",
    },
  },
  text: {
    // marginBottom: "15px",
    paddingLeft: "20px",
    marginLeft: "0px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    // width: "230px",
  },
  text2: {
    paddingLeft: "20px",
    marginLeft: "0px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "230px",
    marginBottom: "10px",
  },
  text1: {
    marginBottom: "20px",
    paddingLeft: "0px",
    marginLeft: "10px",
  },
  textemail: {
    // marginBottom: "20px",
    paddingLeft: "20px",
    marginLeft: "0px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "230px",
  },
  textHeader: {
    marginLeft: "10px",
    marginBottom: "14px",
    fontWeight: "500",
    fontSize: "16px",
  },
  textHeader1: {
    marginBottom: "14px",
    paddingLeft: "20px",
    marginLeft: "0px",
    fontWeight: "600",
  },
  textHeader2: {
    marginLeft: "0px",
    marginBottom: "18px",
    fontWeight: "600",
    paddingLeft: "20px",
  },
  icon: {
    marginRight: "18px",
    color: "var(--primaryColor)",
    marginBottom: "-5px",
    fontSize: "30px",
    "@media(max-width: 1120px)": {
      marginRight: "4px",
      width: "14px",
    },
  },
  actionIcons: {
    color: "rgba(158, 158, 158, 1)",
    fontSize: "22px",
  },

  header2: {
    marginBottom: "15px",
    marginLeft: "10px",
    fontWeight: "800",
  },
  divider: {
    padding: "0.5em",
    minHeight: "80px",
    "@media(min-width: 600px)": {
      borderRight: "0.1em solid rgba(0, 0, 0, 0.1)",
    },
  },
  noDivider: {
    padding: "0.5em",
    minHeight: "80px",
  },
  divPadding: {
    padding: "2px",
  },
  smallTextInput: {
    width: "230px !important",
    height: "36px",
    marginBottom: "25px",
    marginRight: "18px",
  },
  optionalFieldText: {
    color: "#9E9E9E",
    fontSize: "12px",
    fontWeight: "400",
    paddingLeft: "5px",
    marginTop: "5px",
  },
  autoCompleteHeight: {
    "& .MuiAutocomplete-inputRoot": {
      height: "38px",
    },
    "& .MuiAutocomplete-input": {
      padding: "0px 4px !important",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "var(--primaryColor)",
      },
    },
  },
}))

function LocationAddress(props) {
  const {
    data = {},
    setAllowPrompt = () => { },
    isWriteAllowed = false,
    isTablet = false,
    locationType = {},
    setLocationType = () => { },
    storageUnitList = [],
    locationTypeList = [],
    timeZoneList = [],
    paymentType = {},
    paymentGatewayList = [],
    accountType = {},
    storeId = "",
    setData = [],
  } = props;
  let copyPaymentType = paymentType;
  const classes = useStyles()
  const { locationId } = data || {}
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("")
  const { t } = useTranslation()
  const [isAnyChanges, setAnyChanges] = React.useState(false)
  const [countryOpen, setCountryOpen] = React.useState(false)
  const [stateOpen, setStateOpen] = React.useState(false)
  const [isPhoneField, setPhoneField] = useState({ phoneCountryCode: "" })
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [updateData, setUpdateData] = useState({})

  const [locationTypeError, setLocationTypeError] = useState(false)
  const [paymentTypeError, setPaymentTypeError] = useState(false)
  const [accountTypeError, setAccountTypeError] = useState(false)
  const [locationSizeError, setLocationSizeError] = useState(false)

  const [storageOpen, setStorageOpen] = useState(false);

  const [getStorageAddress, setStorageAddress] = useState({});

  let history = useHistory()

  const userProfileDetails = useSelector((state) => state.adminReducer.userProfileDetails) || {}

  const [closeDateError, setCloseDateError] = useState(false)

  const [daysArrState, setDaysArrState] = useState([]) //values?.businessHrs?.days
  const [daysArrError, setDaysArrError] = useState([]) //values?.businessHrs?.days
  const [timeArrState, setTimeArrState] = useState([]) //values?.businessHrs?.time
  const [daysError, setDaysError] = useState([])

  useEffect(() => {
    setEdit(locationId?.length > 0 ? false : true)
  }, [locationId])

  const onGlobalUpdate = () => {
    setAnyChanges(true)
  }

  const handleCustomCloseRedirect = () => {
    setOpenConfirmationDialog(false)
    updateLocationDetails(updateData)
  }

  const updateLocationDetails = async (body) => {
    setAlert(false)
    let response = await ServiceAPI.fetchStoreCommonData(
      locationId?.length > 0 ? API_END_POINTS.updateLocation : API_END_POINTS.addLocation,
      body
    )
    if (response?.data?.status === "success") {
      await setAlertType(_.get(response, "data.status", "error"))
      await setAlertMessage(_.get(response, "data.message", "Try Again Later"))
      setEdit(false)
      await setAlert(true)
      await setLoading(false)
      setAllowPrompt(false)
      setTimeout(() => {
        history.push(`/${userProfileDetails?.roleType}/master-data/locations`)
      }, 2000)
    } else {
      console.log("response", response?.response?.data)
      setLoading(false)
      await setAlert(true)
      await setAlertType("error")
      await setAlertMessage(response?.response?.data?.message)
      setEdit(false)
    }

  }

  //Get Business Hours
  const getBusinessHrs = (data) => {
    let errData = { errFrom: "", errTo: "" }
    if (_.isEmpty(data)) {
      return operatingHrs
    } else {
      return _.forEach(data.business_hrs, function (item) {
        if (item.times.length > 0) {
          let errorItem = []
          _.forEach(item.times, function (DD) {
            errorItem.push(errData)
          })
          return item.errorsArr = errorItem
        } else {
          return item.errorsArr = []
        }
      })
    }
  }

  let mandatoryValidation = {
    location: Yup.string()
      .required("Enter Store Number")
      .matches(
        "^[A-Za-z0-9_@./#+-]*$",
        "Enter valid Store Number"
      )
      .min(1, "Store Number can be of min-1 max-255 characters")
      .max(
        255,
        "Store Number can be of min-1 max-255 characters"
      )
      .strict(true),
    locationName: Yup.string()
      .required(`Enter Store Name`)
      .matches("^[a-zA-Z0-9_-\\s]+$", "Enter valid Store Name")
      .min(1, "Store Name can be of min-1 max-255 characters")
      .max(255, "Store Name can be of min-1 max-255 characters")
      .strict(true),
    phoneNumber: Yup.string()
      .required("Select phoneNumber")
      .min(10)
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
      ),
    locationManager: Yup.string()
      .required(`Enter Store Manager Name`)
      .min(
        1,
        "Store Manager Name can be of min-1 max-255 characters"
      )
      .max(
        255,
        "Store Manager Name can be of min-1 max-255 characters"
      )
      .matches(
        /^[aA-zZ\s]+$/,
        "Only alphabets are allowed for Store Manager Name"
      )
      .strict(true),
    assistantManager: Yup.string()
      .min(
        1,
        "Assistant Manager Name can be of min-1 max-255 characters"
      )
      .max(
        255,
        "Assistant Manager Name can be of min-1 max-255 characters"
      )
      .matches(
        /^[aA-zZ\s]+$/,
        "Only alphabets are allowed for Assistant Manager"
      )
      .strict(true),
    emailAddress: Yup.string()
      .email("Enter valid Email")
      .min(1, "Email can be of min-1 max-255 characters")
      .max(255, "Email can be of min-1 max-255 characters")
      .required("Enter Email Id")
      .strict(true),
    line1: Yup.string()
      .required(`Enter Address line 1`)
      .min(1, "Address line 1 can be of min-1 max-255 characters")
      .max(255, "Address line 1 can be of min-1 max-255 characters")
      .strict(true),
    postalcode: Yup.string()
      .required(`Enter Zipcode`)
      .matches("^[0-9]+$", "Enter valid Zipcode")
      .min(5, "Zipcode can be of min-5 max-10 characters")
      .max(10, "Zipcode can be of min-5 max-10 characters")
      .strict(true),
    returnPolicy: Yup.string()
      .required(`Enter Return Policy`)
      .min(1, "Return Policy can be of min-1 max-200 characters")
      .max(200, "Return Policy can be of min-1 max-200 characters")
      .strict(true),
    locationSizeLength: Yup.string().required("Enter Length"),
    locationSizeWidth: Yup.string().required("Enter Width"),
    locationSizeHeight: Yup.string().required("Enter Height"),
    // paymentMethodType: Yup.object().required("Select Payment Type"),
    // paymentMode: Yup.object().required("Select Account Name"),
    openDate: Yup.string().required("Select Open Date"),
    locationTimezone: Yup.string().required("Select Time Zone"),
    business_hrs: Yup.array().test("Required", "Atleast Add one date", function (item) {
      let dateFlag = true
      let data = _.filter(item, 'selected');
      if (data.length === 0) {
        dateFlag = false
      }
      return dateFlag
    })
      .test("", "", function (item) {
        let dateFlag = true
        _.forEach(item, function (dataItem) {
          (dataItem.errorsArr).length > 0 &&
            _.forEach(dataItem.errorsArr, function (errItem) {
              if (errItem.errFrom !== undefined && errItem.errFrom !== "" &&
                errItem.errFrom !== undefined && errItem.errFrom !== "") {
                dateFlag = false
              }
            })
        })
        return dateFlag
      })
  };

  let optionalValidation = {
    paymentMethodType: Yup.object().required("Select Payment Type"),
    paymentMode: Yup.object().required("Select Account Name"),
  }

  let validationSchema = localStorage.getItem("tenantPaymentConfigEnable") === "true" ? { ...mandatoryValidation, ...optionalValidation } : mandatoryValidation

  console.log("validationSchema", validationSchema)
  return (
    <div>
      <Card
        style={{
          width: "100%",
          boxShadow:
            "0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15)",
          borderRadius: "8px",
        }}
      >
        <Adm.BackdropOverlay open={loading} />

        <>
          <ConfirmationDialog
            openConfirmationDialog={openConfirmationDialog}
            setOpenConfirmationDialog={setOpenConfirmationDialog}
            handleCustomCloseRedirect={handleCustomCloseRedirect}
            deleteContent="Invalid Address. Do you want to continue"
          />
          <Formik
            enableReinitialize
            initialValues={{
              location: _.get(data, "locationId", ""),
              locationName: _.get(data, "locationName", ""),
              phoneNumber:
                _.get(data, "phoneNumber.countryCode", "") +
                (_.get(data, "phoneNumber.number", "") === 0
                  ? ""
                  : _.get(data, "phoneNumber.number", "")),
              emailAddress: _.get(data, "locationSupportEmail", ""),
              locationManager: _.get(data, "locationManager", ""),
              assistantManager: _.get(data, "assistantManager", ""),
              line1: _.get(data, "locationAddress.line1", ""),
              line2: _.get(data, "locationAddress.line2", ""),
              postalcode: _.get(data, "locationAddress.postalcode", ""),
              state: _.get(data, "locationAddress.region", ""),
              country: _.get(data, "locationAddress.country", 'US'),
              city: _.get(data, "locationAddress.city", ""),
              countryCode: _.get(data, "phoneNumber.countryCode", ""),
              latitude: _.get(data, "locationGeoPoint.coordinates[1]", ""),
              longitude: _.get(data, "locationGeoPoint.coordinates[0]", ""),
              returnPolicy: _.get(data, "returnPolicy", ""),
              locationType: _.get(data, "locationType", ""),
              locationSize: _.get(data, "locationSize", {}),
              locationSizeLength: _.get(data, "locationSize.lengthValue", ""),
              locationSizeWidth: _.get(data, "locationSize.widthValue", ""),
              locationSizeHeight: _.get(data, "locationSize.heightValue", ""),
              paymentMethodType: data?.paymentMethodType !== "" ? _.isEmpty(data) ? "" : { id: data?.paymentMethodType, name: data?.paymentMethodType } : _.get(data, "paymentMethodType", ""),
              paymentMode: accountType || _.get(data, "paymentMode", ""),
              storageUnitId: _.get(data, "storageunit_ids[0]", ""),
              isStorageunitAddress: _.get(data, "isStorageunitAddress", false),
              isActive: _.get(data, "isActive", true),
              openDate: _.get(data, "openDate", ""),
              closeDate: _.get(data, "closeDate", ""),
              locationTimezone: _.get(data, "locationTimezone", ""),
              business_hrs: getBusinessHrs(data),
              // business_hrs: _.get(data, "business_hrs", operatingHrs),
              // alcoholTradingHrs: _.get(data, "alcoholTradingHrs", []),
            }}
            validationSchema={Yup.object().shape(validationSchema)}
            onSubmit={async (values, { resetForm }) => {
              setAlert(false)
              setEdit(false)
              setLoading(true)
              let phoneNumberOnly = values.phoneNumber.slice(
                values.countryCode.length
              )
              phoneNumberOnly = phoneNumberOnly === "" ? "" : phoneNumberOnly

              let countryCodeOnly =
                phoneNumberOnly === "" ? "" : values.countryCode

              let newArr = []
              _.forEach(values.business_hrs, function (dd) {
                let GGData = {
                  day: dd.day,
                  selected: dd.selected,
                  times: dd.times
                }
                newArr.push(GGData)
              })

              let body = {
                ...props.sendBackData,
                locationId: values.location,
                locationName: _.trim(values.locationName),
                locationManager: _.trim(values.locationManager),
                assistantManager: _.trim(values.assistantManager),
                locationSupportEmail: values.emailAddress,
                phoneNumber: {
                  countryCode: countryCodeOnly,
                  number: phoneNumberOnly,
                },
                returnPolicy: _.trim(values.returnPolicy),
                locationHierarchy: "",
                locationSize: {
                  lengthValue: values?.locationSizeLength || 0,
                  widthValue: values?.locationSizeWidth || 0,
                  heightValue: values?.locationSizeHeight || 0
                },
                locationAddress: {
                  line1: _.trim(values.line1),
                  line2: _.trim(values.line2),
                  postalcode: values.postalcode,
                  city: values.city.trim(),
                  region: values.state,
                  country: values.country,
                },
                locationGeoPoint: {
                  type: "Point",
                  coordinates: [
                    parseFloat(values.longitude) || 0,
                    parseFloat(values.latitude) || 0,
                  ],
                },
                locationType: {
                  "id": locationTypeList[1]?.id,
                  "name": locationTypeList[1]?.name,
                  "code": locationTypeList[1]?.code
                },
                storeType: {
                  "id": "AS",
                  "name": "Autonomous Store"
                },
                paymentMethodType: values?.paymentMethodType?.name || values?.paymentMethodType,
                paymentAccountName: values?.paymentMode?.name || values?.paymentMode,
                paymentMode: values?.paymentMode?.mode || values?.paymentMode,
                storageunit_ids: locationId?.length > 0 ? undefined : values?.storageUnitId?.length > 0 ? [values?.storageUnitId] : [],
                openDate: values?.openDate,
                closeDate: values?.closeDate ? values?.closeDate : "",
                locationTimezone: values?.locationTimezone,
                // business_hrs: values?.business_hrs,//values?.business_hrs,
                business_hrs: newArr
              }
              delete body["storageUnitId"]
              delete body["alcoholTradingHrs"]
              delete body["businessHrs"]
              const bodyTax = {
                Address1: values.line1,
                Address2: values.line2,
                city: values.city.trim(),
                state: values.state,
                zipcode: values.postalcode,
              }
              let responseTax = await ServiceAPI.fetchStoreTaxData(
                API_END_POINTS.validateAddress,
                bodyTax
              )
              if (responseTax?.response?.data?.error?.statusCode === 400) {
                body = {
                  ...body,
                  isAddressVerified: false,
                }
                setUpdateData(body)
                setEdit(true)
                setOpenConfirmationDialog(true)
                await setLoading(false)
              } else {
                body = {
                  ...body,
                  isAddressVerified: true,
                }
                await setLoading(true)
                setOpenConfirmationDialog(false)
                updateLocationDetails(body)
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
              resetForm,
            }) => {
              const displayPhoneNumber =
                values.phoneNumber.slice(values.countryCode.length).length ===
                  0
                  ? "-"
                  : values?.phoneNumber
              const handleKeyPress = (e, type) => {
                if (type === "number") {
                  if (e.which === 32) {
                    e.preventDefault()
                  }
                } else {
                  if (e.which === 32 && e.target.value === "") {
                    e.preventDefault()
                  }
                }
              }

              const handleCountry = (e, value) => {
                if (value !== null) {
                  setFieldValue("state", "")
                  setFieldValue("country", value)
                  onGlobalUpdate()
                } else {
                  setFieldValue("country", "")
                  setFieldValue("state", "")
                }
              }

              const handleState = (e, value) => {
                if (value !== null) {
                  setAllowPrompt(true)
                }
                setFieldValue("state", value)
                onGlobalUpdate()
              }

              const countryInputChange = () => {
                setFieldValue(
                  "country",
                  values?.country === ""
                    ? props?.defaultCountry
                    : values?.country
                )
              }

              //Handle Open and Close Date Change
              const handleDateChange = (date, type) => {
                type === "openDate" ? setFieldValue("openDate", date) : setFieldValue("closeDate", date)
              }

              //Handle Open and Close Date Change
              const handleTimeZoneChange = (e, value) => {
                setFieldValue("locationTimezone", value)
              }

              const stateInputChange = () => {
                setFieldValue(
                  "state",
                  values?.state === "" ? props?.defaultState : values?.state
                )
              }

              const onChangeZipcode = async (props) => {
                setLoading(false)
                var zipCode = props?.target?.value
                if (zipCode?.length === 5) {
                  await setLoading(true)
                  let response = await ServiceAPI.fetchZipcodeDetails(zipCode)
                  if (response?.data && response?.data.length > 0) {
                    setFieldValue("city", _.get(response, "data[0].city", ""))
                    setFieldValue(
                      "state",
                      _.get(response, "data[0].state", "")
                    )
                  } else {
                    setFieldValue("city", "")
                    setFieldValue("state", "")
                  }
                  await setLoading(false)
                } else {
                  setFieldValue("city", "")
                  setFieldValue("state", "")
                }
              }

              const fetchStorageUnitById = async (storeageId) => {
                await setLoading(true);
                let response = await userApi.getStorageUnitById(`${storeageId}`);
                if (response?.status === 200) {
                  await setLoading(false);
                }
              }

              return (
                <FormMainWrapper
                  onSubmit={handleSubmit}
                  style={{ paddingBottom: "10px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      height: "30px",
                      position: "relative",
                    }}
                  >
                    {edit ? (
                      <div
                        style={{
                          top: "5px",
                          right: "14px",
                          position: "absolute",
                        }}
                      >
                        <Tooltip title="Cancel" placement="top">
                          <IconButton
                            onClick={() => {
                              setEdit(false)
                              setAnyChanges(false)
                              resetForm()
                              setAllowPrompt(false)
                            }}
                            style={{ padding: "9px" }}
                          >
                            <Slash style={{ height: "20px", color: "red" }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Save" placement="top">
                          <IconButton
                            type="submit"
                            onClick={() => {
                              setAnyChanges(false)
                            }}
                            style={{
                              padding: "9px",
                              pointerEvents: `${isAnyChanges ? "unset" : "none"
                                }`,
                            }}
                          >
                            <CheckCircleIcon
                              style={{
                                color: `${isAnyChanges ? "#4CAF50" : "#CCC"}`,
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    ) : (
                      <Tooltip title="Edit" placement="top">
                        <IconButton
                          disabled={isWriteAllowed === true ? false : true}
                          aria-label="delete"
                          onClick={() => {
                            setEdit(true)
                          }}
                          size="small"
                          style={{
                            top: "11px",
                            right: "14px",
                            position: "absolute",
                            padding: "9px",
                          }}
                        >
                          <Edit style={{ height: "20px" }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>

                  <Grid
                    container
                    direction="row"
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    xl={12}
                  >
                    <Grid
                      item
                      lg={3}
                      md={6}
                      sm={6}
                      xs={12}
                      className={classes.divider}
                    >
                      <Typography
                        variant="h5"
                        className={classes.textHeader1}
                      >
                        Store Number{" "}
                        <span style={{ color: "red" }}>
                          {edit ? "*" : ""}{" "}
                        </span>
                      </Typography>
                      {edit ? (
                        <TextField
                          type="text"
                          defaultValue={values.location}
                          variant="outlined"
                          name="location"
                          placeholder="Store Number"
                          error={Boolean(touched.location && errors.location)}
                          helperText={touched.location && errors.location}
                          onBlur={handleBlur}
                          onChange={(props) => {
                            if (values.location !== null) {
                              setAllowPrompt(true)
                            }
                            props.persist()
                            handleChange(props)
                            onGlobalUpdate()
                          }}
                          onKeyPress={(e) => handleKeyPress(e, "number")}
                          size="small"
                          className={classes.textField}
                          disabled={locationId?.length > 0 ? true : false}
                          style={{ backgroundColor: locationId?.length > 0 ? "#E0E0E0" : null }}
                        />
                      ) : (
                        <Typography className={classes.text}>
                          {values.location}
                        </Typography>
                      )}
                    </Grid>

                    <Grid
                      item
                      lg={3}
                      md={6}
                      sm={6}
                      xs={12}
                      className={`${isTablet ? classes.noDivider : classes.divider
                        }`}
                    >
                      <Typography
                        variant="h5"
                        className={classes.textHeader1}
                      >
                        Store Name{" "}
                        <span style={{ color: "red" }}>
                          {edit ? "*" : ""}{" "}
                        </span>
                      </Typography>
                      {edit ? (
                        <TextField
                          defaultValue={values.locationName}
                          variant="outlined"
                          name="locationName"
                          autoComplete="off"
                          placeholder="Store Name"
                          error={Boolean(
                            touched.locationName && errors.locationName
                          )}
                          helperText={
                            touched.locationName && errors.locationName
                          }
                          onKeyPress={(e) => handleKeyPress(e, "text")}
                          onBlur={handleBlur}
                          onChange={(props) => {
                            if (values.locationName !== null) {
                              setAllowPrompt(true)
                            }
                            handleChange(props)
                            onGlobalUpdate()
                          }}
                          className={classes.textField}
                          size="small"
                        />
                      ) : (
                        <Typography className={classes.text}>
                          {values.locationName}
                        </Typography>
                      )}
                    </Grid>

                    <Grid
                      item
                      lg={3}
                      md={3}
                      sm={6}
                      xs={6}
                      className={classes.divider}
                    >
                      <Typography
                        variant="h5"
                        className={classes.textHeader1}
                      >
                        Store Manager{" "}
                        <span style={{ color: "red" }}>
                          {edit ? "*" : ""}{" "}
                        </span>
                      </Typography>
                      {edit ? (
                        <TextField
                          defaultValue={values.locationManager}
                          variant="outlined"
                          autoComplete="off"
                          onKeyPress={(e) => handleKeyPress(e, "text")}
                          name="locationManager"
                          placeholder="Store Manager"
                          className={classes.textField}
                          error={Boolean(
                            touched.locationManager && errors.locationManager
                          )}
                          helperText={
                            touched.locationManager && errors.locationManager
                          }
                          onBlur={handleBlur}
                          onChange={(props) => {
                            if (values.locationManager !== null) {
                              setAllowPrompt(true)
                            }
                            handleChange(props)
                            onGlobalUpdate()
                          }}
                          size="small"
                        />
                      ) : (
                        <Typography className={classes.text}>
                          {values.locationManager
                            ? values.locationManager
                            : "-"}
                        </Typography>
                      )}
                    </Grid>

                    <Grid
                      item
                      lg={3}
                      md={3}
                      sm={6}
                      xs={6}
                      className={`${isTablet ? classes.noDivider : classes.divider
                        }`}
                    >
                      <Grid container direction="row">
                        <Typography
                          variant="h5"
                          className={classes.textHeader1}
                        >
                          Assistant Manager{" "}
                        </Typography>
                        {edit ? (
                          <Typography
                            variant="h5"
                            style={{
                              color: "#9E9E9E",
                              fontSize: "12px",
                              fontWeight: "400",
                              paddingLeft: "5px",
                              marginTop: "3px",
                            }}
                          >
                            (optional)
                          </Typography>
                        ) : null}
                      </Grid>

                      {edit ? (
                        <TextField
                          defaultValue={values.assistantManager}
                          variant="outlined"
                          autoComplete="off"
                          name="assistantManager"
                          placeholder="Assistant Manager"
                          onKeyPress={(e) => handleKeyPress(e, "text")}
                          error={Boolean(
                            touched.assistantManager &&
                            errors.assistantManager
                          )}
                          helperText={
                            touched.assistantManager &&
                            errors.assistantManager
                          }
                          onBlur={handleBlur}
                          className={classes.textField}
                          onChange={(props) => {
                            if (values.assistantManager !== null) {
                              setAllowPrompt(true)
                            }
                            handleChange(props)
                            onGlobalUpdate()
                          }}
                          size="small"
                        />
                      ) : (
                        <Typography className={classes.text}>
                          {values.assistantManager
                            ? values.assistantManager
                            : "-"}
                        </Typography>
                      )}
                    </Grid>


                  </Grid>

                  <Divider
                    variant="middle"
                    style={{ marginTop: "15px", marginBottom: "15px" }}
                  />

                  <Grid container direction="row">
                    <Grid
                      container
                      item
                      lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={12}
                      direction="row"
                      className={`${isTablet ? classes.noDivider : classes.divider
                        }`}
                    >
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography
                          variant="h5"
                          className={classes.textHeader1}
                        >
                          Geographic Position{" "}
                        </Typography>
                      </Grid>
                      {edit ? (
                        <>
                          <Grid item lg={6} md={6} sm={6} xs={6}>
                            <TextField
                              defaultValue={values.latitude}
                              variant="outlined"
                              name="latitude"
                              label="Latitude"
                              placeholder="Latitude"
                              onKeyPress={(e) => handleKeyPress(e, "text")}
                              error={Boolean(
                                touched.latitude && errors.latitude
                              )}
                              helperText={touched.latitude && errors.latitude}
                              onBlur={handleBlur}
                              className={classes.textField}
                              onChange={(props) => {
                                if (values.latitude !== null) {
                                  setAllowPrompt(true)
                                }
                                handleChange(props)
                                onGlobalUpdate()
                              }}
                              size="small"
                            />
                          </Grid>
                          <Grid item lg={6} md={6} sm={6} xs={6}>
                            <TextField
                              defaultValue={values.longitude}
                              variant="outlined"
                              name="longitude"
                              label="Longitude"
                              placeholder="Longitude"
                              onKeyPress={(e) => handleKeyPress(e, "text")}
                              error={Boolean(
                                touched.longitude && errors.longitude
                              )}
                              helperText={
                                touched.longitude && errors.longitude
                              }
                              onBlur={handleBlur}
                              className={classes.textField}
                              onChange={(props) => {
                                if (values.longitude !== null) {
                                  setAllowPrompt(true)
                                }
                                handleChange(props)
                                onGlobalUpdate()
                              }}
                              size="small"
                            />
                          </Grid>
                        </>
                      ) : (
                        <Typography className={classes.text}>
                          {values.latitude &&
                            values.longitude &&
                            `${values.latitude},${values.longitude}`}
                        </Typography>
                      )}
                    </Grid>

                    <Grid
                      item
                      lg={3}
                      md={6}
                      sm={6}
                      xs={12}
                      className={classes.divider}
                    >
                      <Grid container direction="row">
                        <Typography
                          variant="h5"
                          className={classes.textHeader1}
                        >
                          Support Phone Number{" "}
                          <span style={{ color: "red" }}>
                            {edit ? "*" : ""}{" "}
                          </span>
                        </Typography>
                      </Grid>

                      {edit ? (
                        <div
                          style={{ marginLeft: "15px", marginBottom: "5px" }}
                        >
                          <Adm.PhoneField
                            name="phoneNumber"
                            autoComplete="off"
                            entity="Location"
                            onKeyPress={(e) => handleKeyPress(e, "number")}
                            defaultValue={
                              values.phoneNumber !== "0"
                                ? values.phoneNumber.toString()
                                : ""
                            }
                            className="location-base-phone-field"
                            onUpdate={({
                              value,
                              dialCode,
                              format,
                              countryCode,
                            }) => {
                              const formatText =
                                format !== undefined &&
                                format
                                  .replace("+", "")
                                  .replace("-", "")
                                  .replaceAll(" ", "")
                              setPhoneField({
                                phoneCountryCode: countryCode,
                                phoneCount: formatText.length,
                                phoneCode: dialCode,
                              })
                              setFieldValue("phoneNumber", value)
                              setFieldValue("countryCode", dialCode)
                              onGlobalUpdate()
                            }}
                            defaultErrorValue={Boolean(
                              touched.phoneNumber && errors.phoneNumber
                            )}
                          />
                        </div>
                      ) : (
                        <Typography className={classes.text}>
                          {"+"}{displayPhoneNumber}
                        </Typography>
                      )}
                    </Grid>
                    <Grid
                      item
                      lg={3}
                      md={6}
                      sm={6}
                      xs={12}
                      className={`${classes.noDivider}`}
                    >
                      <Grid container direction="row">
                        <Typography
                          variant="h5"
                          className={classes.textHeader1}
                        >
                          Support Email Address{" "}
                          <span style={{ color: "red" }}>
                            {edit ? "*" : ""}{" "}
                          </span>
                        </Typography>
                      </Grid>

                      {edit ? (
                        <TextField
                          defaultValue={values.emailAddress}
                          variant="outlined"
                          name="emailAddress"
                          autoComplete="off"
                          placeholder="Email"
                          onKeyPress={(e) => handleKeyPress(e, "text")}
                          error={Boolean(
                            touched.emailAddress && errors.emailAddress
                          )}
                          helperText={
                            touched.emailAddress && errors.emailAddress
                          }
                          onBlur={handleBlur}
                          onChange={(props) => {
                            if (values.emailAddress !== null) {
                              setAllowPrompt(true)
                            }
                            handleChange(props)
                            onGlobalUpdate()
                          }}
                          className={classes.textField}
                          size="small"
                        />
                      ) : (
                        <Typography className={classes.textemail}>
                          {values.emailAddress ? values.emailAddress : "-"}
                        </Typography>
                      )}
                    </Grid>

                    <Grid
                      item
                      lg={3}
                      md={3}
                      sm={6}
                      xs={6}
                      className={`${isTablet ? classes.noDivider : classes.divider
                        }`}
                    >
                      <Grid container direction="row">
                        <Typography
                          variant="h5"
                          className={classes.textHeader1}
                        >
                          Return Policy{" "}
                          <span style={{ color: "red" }}>
                            {edit ? "*" : ""}{" "}
                          </span>
                        </Typography>
                      </Grid>

                      {edit ? (
                        <TextField
                          defaultValue={values.returnPolicy}
                          variant="outlined"
                          autoComplete="off"
                          name="returnPolicy"
                          placeholder="Return Policy"
                          onKeyPress={(e) => handleKeyPress(e, "text")}
                          error={Boolean(
                            touched.returnPolicy && errors.returnPolicy
                          )}
                          helperText={
                            touched.returnPolicy && errors.returnPolicy
                          }
                          onBlur={handleBlur}
                          className={classes.textField}
                          onChange={(props) => {
                            if (values.returnPolicy !== null) {
                              setAllowPrompt(true)
                            }
                            handleChange(props)
                            onGlobalUpdate()
                          }}
                          size="small"
                        />
                      ) : (
                        <Typography className={classes.text}>
                          {values.returnPolicy ? values.returnPolicy : "-"}
                        </Typography>
                      )}
                    </Grid>

                  </Grid>

                  <Divider
                    variant="middle"
                    style={{ marginTop: "15px", marginBottom: "15px" }}
                  />

                  <Grid container direction="row">
                    <Grid
                      item
                      lg={3}
                      md={6}
                      sm={6}
                      xs={12}
                      className={`${classes.noDivider}`}
                    >
                      <Grid container direction="row">
                        <Typography
                          variant="h5"
                          className={classes.textHeader1}
                        >
                          Storage Unit{" "}
                        </Typography>
                      </Grid>

                      {edit ? (
                        <Adm.DropdownFilter
                          list={storageUnitList || []}
                          defaultValue={values?.storageUnitId}
                          selectedValue={values?.storageUnitId}
                          onChangeEvent={(e, val) => {
                            setAnyChanges(true)
                            setStorageAddress(val?.address || {})
                            setFieldValue("storageUnitId", val.id);
                            setFieldValue("isStorageunitAddress", false)
                            if (values?.isStorageunitAddress) {
                              setFieldValue("line1", "")
                              setFieldValue("line2", "")
                              setFieldValue("postalcode", "")
                              setFieldValue("state", "")
                              setFieldValue("country", "")
                              setFieldValue("city", "")
                            }

                          }}
                          popupOpen={storageOpen}
                          setPopupOpen={setStorageOpen}
                          disableClearable={true}
                          classNameTextField={classes.autoCompleteHeight}
                          placeholder={'Select Storage'}
                          style={{ width: '95%', paddingLeft: '15px', backgroundColor: locationId?.length > 0 ? "#f6f6f6" : null }}
                          disabled={locationId?.length > 0 ? true : false}
                        />
                      ) : (
                        <Typography className={classes.textemail}>
                          {values.storageUnitId ? values.storageUnitId : "-"}
                        </Typography>
                      )}
                    </Grid>

                    {_.isEmpty(locationId) && values?.storageUnitId && <Grid
                      item
                      lg={3}
                      md={6}
                      sm={6}
                      xs={12}
                      className={`${classes.noDivider}`}
                    >

                      <Grid container direction="row">
                        <Typography
                          variant="h5"
                          className={classes.textHeader1}
                        >
                          {`Copy Address from Storage Unit`}
                        </Typography>
                      </Grid>

                      {edit ? (
                        <Checkbox
                          checked={values?.isStorageunitAddress || false}
                          disabled={values?.storageUnitId?.length > 0 && _.isEmpty(locationId) ? false : true}
                          onChange={e => {
                            setAnyChanges(true)
                            setFieldValue("isStorageunitAddress", e.target.checked)

                            if (e.target.checked) {
                              setFieldValue("line1", getStorageAddress?.line1)
                              setFieldValue("line2", getStorageAddress?.line1)
                              setFieldValue("postalcode", getStorageAddress?.postal_code)
                              setFieldValue("state", getStorageAddress?.region)
                              setFieldValue("country", getStorageAddress?.country)
                              setFieldValue("city", getStorageAddress?.city)
                            }
                            else {
                              setFieldValue("line1", "")
                              setFieldValue("line2", "")
                              setFieldValue("postalcode", "")
                              setFieldValue("state", "")
                              setFieldValue("country", "")
                              setFieldValue("city", "")
                            }
                          }}
                          value="checkedA"
                          inputProps={{
                            'aria-label': 'primary checkbox',
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </Grid>}
                  </Grid>

                  <Divider
                    variant="middle"
                    style={{ marginTop: "15px", marginBottom: "15px" }}
                  />

                  <Typography
                    variant="h3"
                    style={{
                      position: "relative",
                      paddingBottom: "20px",
                      paddingTop: "3px",
                      paddingLeft: "25px",
                      display: "flex",
                    }}
                  >
                    {"Address Info "}
                    <span style={{ color: "red" }}>{edit ? " *" : ""} </span>
                    {data?.isAddressVerified === true ? (
                      <div style={{ display: "flex", marginLeft: "5px" }}>
                        <CheckCircleIcon
                          style={{
                            color: "#4CAF50",
                            fontSize: "20px",
                          }}
                        />
                        <Typography style={{ marginLeft: "5px" }}>
                          {" "}
                          Verified
                        </Typography>
                      </div>
                    ) : (
                      <div style={{ display: "flex", marginLeft: "5px" }}>
                        <InfoOutlined style={{ color: "#f44336" }} />
                        <Typography style={{ marginLeft: "5px" }}>
                          {" "}
                          Not Verified
                        </Typography>
                      </div>
                    )}
                  </Typography>

                  <Grid
                    container
                    direction="row"
                    style={{ padding: "10px 5px", margin: "0px" }}
                    spacing={4}
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Grid item lg={4} md={4} sm={4} xs={12} xl={4}>
                      <Typography
                        variant="h5"
                        className={classes.textHeader1}
                      >
                        Address Line 1 {' '}
                        <span style={{ color: "red" }}>
                          {edit ? "*" : ""}{" "}
                        </span>
                      </Typography>
                      {edit ? (
                        <TextField
                          disabled={values?.isStorageunitAddress || false}
                          style={{ backgroundColor: values?.isStorageunitAddress ? "#f6f6f6" : null }}
                          defaultValue={values?.line1}
                          value={values?.line1}
                          variant="outlined"
                          autoComplete="off"
                          name="line1"
                          placeholder="Address Line 1"
                          onKeyPress={(e) => handleKeyPress(e, "text")}
                          className={classes.textField}
                          error={Boolean(touched.line1 && errors.line1)}
                          helperText={touched.line1 && errors.line1}
                          onBlur={handleBlur}
                          onChange={(props) => {
                            if (values?.line1 !== null) {
                              setAllowPrompt(true)
                            }
                            handleChange(props)
                            onGlobalUpdate()
                          }}
                          size="small"
                        />
                      ) : (
                        <Typography className={classes.text}>
                          {values?.line1 || "-"}
                        </Typography>
                      )}
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={12} xl={4}>
                      <Grid container direction="row">
                        <Typography
                          variant="h5"
                          className={classes.textHeader1}
                        >
                          Address Line 2
                        </Typography>
                        {edit ? (
                          <Typography
                            variant="h5"
                            style={{
                              color: "#9E9E9E",
                              fontSize: "12px",
                              fontWeight: "400",
                              paddingLeft: "5px",
                              marginTop: "3px",
                            }}
                          >
                            (optional)
                          </Typography>
                        ) : null}
                      </Grid>

                      {edit ? (
                        <TextField
                          disabled={values?.isStorageunitAddress || false}
                          style={{ backgroundColor: values?.isStorageunitAddress ? "#f6f6f6" : null }}
                          defaultValue={values?.line2}
                          value={values?.line2}
                          variant="outlined"
                          name="line2"
                          autoComplete="off"
                          placeholder="Address Line 2"
                          onKeyPress={(e) => handleKeyPress(e, "text")}
                          className={classes.textField}
                          error={Boolean(touched.line2 && errors.line2)}
                          helperText={touched.line2 && errors.line2}
                          onBlur={handleBlur}
                          onChange={(props) => {
                            if (values?.line2 != null) {
                              setAllowPrompt(true)
                            }
                            handleChange(props)
                            onGlobalUpdate()
                          }}
                          size="small"
                        />
                      ) : (
                        <Typography className={classes.text}>
                          {_.get(values, "line2", "-") || "-"}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12} xl={4}>
                      <Typography
                        variant="h5"
                        className={classes.textHeader1}
                      >
                        Country
                        <span style={{ color: "red" }}>
                          {edit ? "*" : ""}{" "}
                        </span>
                      </Typography>
                      {edit ? (
                        <TextField
                          variant="outlined"
                          size="small"
                          className={classes.textField}
                          type="text"
                          autoComplete="off"
                          name="country"
                          placeholder="Country"
                          value={values?.country}
                          disabled={true}
                          style={{ backgroundColor: "#f6f6f6" }}
                        />
                      ) : (

                        <Typography
                          className={classes.text}
                        >
                          {values?.country}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    style={{ padding: "10px 5px", margin: "0px" }}
                    spacing={4}
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >

                    <Grid item lg={4} md={4} sm={4} xs={12} xl={4}>
                      <Grid container direction="row">
                        <Typography
                          variant="h5"
                          className={classes.textHeader1}
                        >
                          State
                        </Typography>
                        {edit ? (
                          <Typography
                            variant="h5"
                            style={{
                              color: "#9E9E9E",
                              fontSize: "12px",
                              fontWeight: "400",
                              paddingLeft: "5px",
                              marginTop: "1px",
                            }}
                          >
                            <span style={{ color: "red" }}>
                              {edit ? "*" : ""}{" "}
                            </span>
                            State is auto-populated from Zipcode
                          </Typography>
                        ) : null}
                      </Grid>
                      {edit ? (
                        <TextField
                          variant="outlined"
                          size="small"
                          className={classes.textField}
                          type="text"
                          autoComplete="off"
                          name="state"
                          placeholder="State"
                          value={values?.state || ""}
                          disabled={true}
                          style={{ backgroundColor: "#f6f6f6" }}
                        />
                      ) : (
                        <Typography className={classes.text}>
                          {values?.state || ""}
                        </Typography>
                      )}
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={12} xl={4}>
                      <Grid container direction="row">
                        <Typography
                          variant="h5"
                          className={classes.textHeader1}
                        >
                          City {' '}
                          <span style={{ color: "red" }}>
                            {edit ? "*" : ""}{" "}
                          </span>
                        </Typography>
                        {edit ? (
                          <Typography
                            variant="h5"
                            style={{
                              color: "#9E9E9E",
                              fontSize: "12px",
                              fontWeight: "400",
                              paddingLeft: "5px",
                              marginTop: "1px",
                            }}
                          >
                            City is auto-populated from Zipcode
                          </Typography>
                        ) : null}
                      </Grid>

                      {edit ? (
                        <TextField
                          value={values?.city}
                          variant="outlined"
                          name="city"
                          // autoComplete="off"
                          placeholder="City"
                          className={classes.textField}

                          size="small"
                          disabled={true}
                          style={{ backgroundColor: "#f6f6f6" }}
                        />
                      ) : (
                        <Typography className={classes.text}>
                          {values?.city}
                        </Typography>
                      )}
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={12} xl={4}>
                      <Typography
                        variant="h5"
                        className={classes.textHeader1}
                      >
                        Zip Code {' '}
                        <span style={{ color: "red" }}>
                          {edit ? "*" : ""}{" "}
                        </span>
                      </Typography>
                      {edit ? (
                        <TextField
                          disabled={values?.isStorageunitAddress || false}
                          style={{ backgroundColor: values?.isStorageunitAddress ? "#f6f6f6" : null }}
                          defaultValue={values.postalcode}
                          value={values.postalcode}
                          variant="outlined"
                          name="postalcode"
                          autoComplete="off"
                          placeholder="Postal Code"
                          className={classes.textField}
                          error={Boolean(
                            touched?.postalcode &&
                            (values?.city === "" ||
                              values?.state === "" ||
                              values?.postalcode === "")
                          )}
                          helperText={
                            touched?.postalcode &&
                            (values?.postalcode === ""
                              ? errors?.postalcode
                              : values?.city === "" || values?.state === ""
                                ? "Enter valid zipcode"
                                : "")
                          }
                          onBlur={handleBlur}
                          onKeyPress={(e) => handleKeyPress(e, "number")}
                          onChange={(props) => {
                            onChangeZipcode(props)
                            if (values.postalcode !== null) {
                              setAllowPrompt(true)
                            }
                            handleChange(props)
                            onGlobalUpdate()
                          }}
                          size="small"
                        />
                      ) : (
                        <Typography className={classes.text}>
                          {values?.postalcode}
                        </Typography>
                      )}
                    </Grid>

                  </Grid>

                  <Divider />
                  {<EditLocationHierarchy
                    {...props}
                    setFieldValue={setFieldValue}
                    values={values}
                    errors={errors}
                    touched={touched}
                    edit={edit}
                    setEdit={setEdit}
                    locationType={locationType}
                    setLocationType={setLocationType}
                    isAnyChanges={isAnyChanges}
                    setAnyChanges={setAnyChanges}
                    locationTypeError={locationTypeError}
                    setLocationTypeError={setLocationTypeError}
                    paymentTypeError={paymentTypeError}
                    setPaymentTypeError={setPaymentTypeError}
                    accountTypeError={accountTypeError}
                    setAccountTypeError={setAccountTypeError}
                    locationSizeError={locationSizeError}
                    setLocationSizeError={setLocationSizeError}
                    locationId={locationId}
                  />}

                  <Adm.OpenDate
                    {...props}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    data={data}
                    setData={setData}
                    storeId={storeId}
                    isWriteAllowed={isWriteAllowed}
                    setAllowPrompt={setAllowPrompt}
                    isTablet={isTablet}
                    edit={edit}
                    values={values}
                    timeZoneList={timeZoneList}
                    handleDateChange={handleDateChange}
                    handleTimeZoneChange={handleTimeZoneChange}
                    onGlobalUpdate={onGlobalUpdate}
                  />
                  <Features.OperationHoursKit
                    defaultList={[values.business_hrs]}
                    edit={edit}
                    errors={errors}
                    touched={touched}
                    onGlobalUpdate={onGlobalUpdate}
                    onUpdate={(e) => {
                      onGlobalUpdate()
                      setFieldValue("business_hrs", e[0])
                    }}
                  />
                </FormMainWrapper>
              )
            }}
          </Formik>
        </>
      </Card>
      <>
        {alert ? (
          <Adm.SnackBar open={true} message={alertMessage} type={alertType} />
        ) : null}
      </>
    </div>
  )
}
export { LocationAddress }
export default LocationAddress
