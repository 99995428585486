import {
  Layout,
  List,
  Monitor,
  Users,
  FileText,
  Package,
  Briefcase,
  Cpu,
  Eye,
  AlertIconKit,
  BarChartIcon,
} from "@iconsGallery"
import UserService from "../services/UserService"
import PageComponent from "./PageComponentLibrary"
import { EqualizerOutlined } from "@iconsGallery"
import { StoreEquipmentIcon } from "../IconsGallery/IconsGallery.jsx"
import {
  FrictionlessIcon,
  AutonomousStoreIcon,
  PlanogramIcon,
  SupportIcon,
  TransactionIcon,
  TaxConfigurationIcon,
  UserActivitiesIcon,
  LogViewerIcon,
  UnlockIcon,
} from "../IconsGallery/IconsGallery.jsx"

const { location = {}, _kc = {}, globalPathName = "" } = window || {}
const { idTokenParsed = {} } = _kc || {}
const { userRole = {} } = idTokenParsed || {}

const dashboardsRoutes = {
  id: "Dashboard",
  path: "/dashboard",
  readAccessPath: "Dashboard Data.Read",
  icon: <Layout />,
  component: PageComponent.SassDashboard,
  children: null,
}
const planogramDetailRoutes = {
  id: "PlanogramDetails",
  path: "/autonomous-store/planogram/planogramdetail/:locationId/:planogramId",
  readAccessPath: "Content.Read",
  writeAccessPath: "Content.Write",
  icon: <Layout />,
  component: PageComponent.PlanogramDetails,
  children: null,
}
const oldDashboardsRoutes = {
  id: "oldDashboard",
  path: "/olddashboard",
  readAccessPath: "Dashboard Data.Read",
  icon: <Layout />,
  component: PageComponent.Dashboard,
  children: null,
}

// Content
const contentRoutes = {
  id: "Content",
  path: "/content",
  readAccessPath: "Content.Read",
  writeAccessPath: "Content.Write",
  icon: <FileText />,
  children: [
    // {
    //   path: "/content/countries",
    //   name: "Countries",
    //   component: PageComponent.Countries,
    //   readAccessPath: "Content.Read",
    //   writeAccessPath: "Content.Write",
    // },
    // {
    //   path: "/content/us-states",
    //   name: "States",
    //   component: PageComponent.USStates,
    // },
    // {
    //   path: "/content/currencies",
    //   name: "Currencies",
    //   component: PageComponent.Currencies,
    //   readAccessPath: "Content.Read",
    //   writeAccessPath: "Content.Write",
    // },
    {
      path: "/content/shelftype",
      name: "Shelf Type",
      component: PageComponent.Digitshelf,
      readAccessPath: "Content.Read",
      writeAccessPath: "Content.Write",
    },
    {
      path: "/content/gender",
      name: "Gender",
      component: PageComponent.Gender,
      readAccessPath: "Content.Read",
      writeAccessPath: "Content.Write",
    },
    // {
    //   path: "/content/canadian-provinces-list",
    //   name: "Canadian Provinces List",
    //   component: PageComponent.CanadianProvincesList,
    // },
    // {
    //   path: "/content/languages",
    //   name: "Languages",
    //   component: PageComponent.Languages,
    //   readAccessPath: "Content.Read",
    //   writeAccessPath: "Content.Write",
    // },
    {
      path: "/content/units-of-measure",
      name: "Units of Measure",
      component: PageComponent.UnitsOfMeasure,
      readAccessPath: "Content.Read",
      writeAccessPath: "Content.Write",
    },
    {
      path: "/content/barcode-types",
      name: "Barcode Types",
      component: PageComponent.BarcodeTypes,
      readAccessPath: "Content.Read",
      writeAccessPath: "Content.Write",
    },
    {
      path: "/content/tax",
      name: "Tax Code",
      component: PageComponent.Tax,
      readAccessPath: "Content.Read",
      writeAccessPath: "Content.Write",
    },
  ],
  component: null,
}

const masterDataLocationDetailedScreenRoutes = {
  path: "/master-data/locations/location-detailed-page/storeId=:id?",
  //path: "/master-data/locations/location-detailed-page/storeId=:id?",
  readAccessPath: "Master Data.Location - Read",
  writeAccessPath: "Master Data.Location - Write",
  component: PageComponent.LocationTabLandingPage,
}
const masterDataLocationGroupDetailedScreenRoutes = {
  path:
    "/master-data/locations/groups/location-group-detailed-page/groupId=:id",
  component: PageComponent.LocationGroupDetailedPage,
  readAccessPath: "Master Data.Location Group - Read",
  writeAccessPath: "Master Data.Location Group - Write",
}

const masterDataProductGroupDetailedScreenRoutes = {
  path: "/master-data/products/groups/product-group-detailed-page/groupID=:id",
  readAccessPath: "Master Data.Product - Read",
  writeAccessPath: "Master Data.Product - Write",
  component: PageComponent.ProductGroupDetailedPage,
}

const masterDataCustomerDetailedScreenRoutes = {
  path: "/master-data/customers/customer-detailed-page/customerId=:id",
  readAccessPath: "Master Data.Customer - Read",
  writeAccessPath: "Master Data.Customer - Write",
  component: PageComponent.CustomerDetailedPage,
}

const masterDataProductDetailedScreenRoutes = {
  path: "/master-data/products/product-detailed-page/:id",
  readAccessPath: "Master Data.Product - Read",
  writeAccessPath: "Master Data.Product - Write",
  component: PageComponent.EditProduct,
}

const masterDataInventoryDetailedScreenRoutes = {
  path: "/inventory/inventory-detailed-page/inventoryId=:id",
  component: PageComponent.InventoryDetailedPage,
  readAccessPath: "Master Data.Inventory - Read",
  writeAccessPath: "Master Data.Inventory - Write",
}

const masterDataAddNewAddressScreenRoutes = {
  path: "/master-data/customers/add-new-address",
  component: PageComponent.AddNewAddress,
}

const masterDataPriceDetailedScreenRoutes = {
  path: "/master-data/price/price-detailed-page/priceId=:id",
  component: PageComponent.PriceDetailedPage,
}

const masterDataLocationGroups = {
  path: "/master-data/locations/groups",
  component: PageComponent.Groups,
  readAccessPath: "Master Data.Location Group - Read",
  writeAccessPath: "Master Data.Location Group - Write",
}

const masterDataLocationHierarchy = {
  path: "/master-data/locations/hierarchy",
  component: PageComponent.Hierarchy,
  readAccessPath: "Master Data.Location Hierarchy - Read",
  writeAccessPath: "Master Data.Location Hierarchy - Write",
}

const masterDataLocationMapping = {
  path: "/master-data/locations/hierarchyMapping",
  component: PageComponent.HierarchyMapping,
  readAccessPath: "Master Data.Location Hierarchy Mapping - Read",
  writeAccessPath: "Master Data.Location Hierarchy Mapping - Write",
}

// const masterDataLocationPos = {
//   path: "/frictionless-store/sdc/pos",
//   readAccessPath: 'Master Data.Locations Pos - Read',
//   writeAccessPath: 'Master Data.Locations Pos - Write',
//   component: PageComponent.ConfigurePos,
// }

const masterDataPosDetailedScreenRoutes = {
  path: "/frictionless-store/sdc/pos/pos-detailed-page/:locationId/:posId",
  component: PageComponent.PosDetailedScreen,
  readAccessPath: "Master Data.Locations Pos - Read",
  writeAccessPath: "Master Data.Locations Pos - Write",
}

const masterDataproductsGroups = {
  path: "/master-data/products/groups",
  component: PageComponent.ProductsGroups,
  readAccessPath: "Master Data.Product Group - Read",
  writeAccessPath: "Master Data.Product Group - Write",
}

const masterDataproductsHierarchy = {
  path: "/master-data/products/hierarchy",
  readAccessPath: "Master Data.Product Category - Read",
  writeAccessPath: "Master Data.Product Category - Write",
  component: PageComponent.ProductDataHierarchy,
}

const masterDataproductsMapping = {
  path: "/master-data/products/productMapping",
  component: PageComponent.HierarchyMapping,
  readAccessPath: "Master Data.Product Category Mapping - Read",
  writeAccessPath: "Master Data.Product Category Mapping - Write",
}

const masterDataproductsAttribute = {
  path: "/master-data/products/attribute",
  component: PageComponent.ProductAttribute,
  readAccessPath: "Master Data.Product Attributes - Read",
  writeAccessPath: "Master Data.Product Attributes - Write",
}

const masterDataInventoryAdjustments = {
  path: "/inventory/adjustments",
  component: PageComponent.InventoryAdjustments,
  readAccessPath: "Master Data.Inventory Adjustments - Read",
  writeAccessPath: "Master Data.Inventory Adjustments - Write",
}

const createUpdateStorageRoutes = {
  id: "Create Location",
  path: "/master-data/Storage/createupdatestorage/:storageId?/",
  icon: <Monitor />,
  component: PageComponent.StorageLoadingPage,
  readAccessPath: "Master Data.Location - Read",
  writeAccessPath: "Master Data.Location - Write",
  children: null,
}

const createLocationRoutes = {
  id: "Create Location",
  path: "/master-data/locations/create",
  icon: <Monitor />,
  component: PageComponent.CreateLocation,
  readAccessPath: "Master Data.Location - Read",
  writeAccessPath: "Master Data.Location - Write",
  children: null,
}

const createPosRoutes = {
  id: "Create Pos",
  path: "/frictionless-store/sdc/pos/create/:LocationNum",
  icon: <Monitor />,
  component: PageComponent.CreateNewPos,
}

const createCustomerRoutes = {
  id: "Create Customer",
  path: "/master-data/customers/create",
  icon: <Monitor />,
  component: PageComponent.CreateCustomer,
  children: null,
}

const createProductRoutes = {
  id: "Create Product",
  path: "/master-data/products/create",
  readAccessPath: "Master Data.Product - Read",
  writeAccessPath: "Master Data.Product - Write",
  icon: <Monitor />,
  component: PageComponent.CreateProduct,
  children: null,
}

const editProductRoutes = {
  id: "Edit Product",
  path: "/master-data/products/editProduct/:storeID/:upc",
  icon: <Monitor />,
  component: PageComponent.EditProduct,
  children: null,
}

const createProductGroup = {
  id: "Create Product Group",
  path: "/master-data/products/createProductGroup/:groupID/:groupName",
  icon: <Monitor />,
  component: PageComponent.CreateProductGroup,
  children: null,
}

const inventoryDetails = {
  id: "Inventory Details",
  path: "/inventory/store/inventoryDetails/:locationId/:itemId",
  readAccessPath: "Master Data.Inventory - Read",
  writeAccessPath: "Master Data.Inventory - Write",
  icon: <Monitor />,
  component: PageComponent.InventoryDetails,
  children: null,
}
const inventoryStorageDetails = {
  id: "Inventory Storage Details",
  path: "/inventory/storage/inventoryDetails/:locationId/:itemId",
  readAccessPath: "Master Data.Inventory - Read",
  writeAccessPath: "Master Data.Inventory - Write",
  icon: <Monitor />,
  component: PageComponent.InventoryStorageDetails,
  children: null,
}

const adjustmentDetails = {
  id: "Adjustment Details",
  path:
    "/inventory/store/adjustments/view/:locationId/:locationName/:userId/:date",
  readAccessPath: "Master Data.Inventory Adjustments - Read",
  writeAccessPath: "Master Data.Inventory Adjustments - Write",
  icon: <Monitor />,
  component: PageComponent.AdjustmentsDetails,
  children: null,
}
const adjustmentStorageDetails = {
  id: "Adjustment Details",
  path:
    "/inventory/storage/adjustments/view/:locationId/:locationName/:userId/:date",
  readAccessPath: "Master Data.Inventory Adjustments - Read",
  writeAccessPath: "Master Data.Inventory Adjustments - Write",
  icon: <Monitor />,
  component: PageComponent.AdjustmentsStorageDetails,
  children: null,
}

const invetoryAdjustmentDetails = {
  id: "Invetory Adjustment Details",
  path:
    "/inventory/store/adjustments/InventoryAdjustment/:locationId/:locationName",
  readAccessPath: "Master Data.Inventory Adjustments - Read",
  writeAccessPath: "Master Data.Inventory Adjustments - Write",
  icon: <Monitor />,
  component: PageComponent.AdjustmentsInventoryDetails,
  children: null,
}
const invetoryAdjustmentStorageDetails = {
  id: "Invetory Adjustment Details",
  path:
    "/inventory/storage/adjustments/InventoryAdjustment/:locationId/:locationName",
  readAccessPath: "Master Data.Inventory Adjustments - Read",
  writeAccessPath: "Master Data.Inventory Adjustments - Write",
  icon: <Monitor />,
  component: PageComponent.AdjustmentsStorageInventoryDetails,
  children: null,
}

const invetoryCycleCountsDetails = {
  id: "Invetory CycleCounts Details",
  path:
    "/inventory/store/cycleCounts/inventoryCycleCounts/:locationId/:locationName",
  readAccessPath: "Master Data.Inventory Adjustments - Read",
  writeAccessPath: "Master Data.Inventory Adjustments - Write",
  icon: <Monitor />,
  component: PageComponent.CycleCountsInventoryDetails,
  children: null,
}

const createInventoryRoutes = {
  id: "Create Inventory",
  path: "/inventory/create",
  readAccessPath: "Master Data.Inventory - Read",
  writeAccessPath: "Master Data.Inventory - Write",
  icon: <Monitor />,
  component: PageComponent.CreateInventory,
  children: null,
}

const createEquipmentRoutes = {
  id: "Create Equipment",
  path: "/storeEquipment/CreateEquipment",
  icon: <Monitor />,
  component: PageComponent.CreateEquipment,
  children: null,
}

const createPriceRoutes = {
  id: "Create Price",
  path: "/master-data/price/create",
  icon: <Monitor />,
  component: PageComponent.CreatePrice,
  children: null,
}

const GroupLocationsListRoutes = {
  id: "Group Locations List",
  path: "/master-data/locations/groups/create",
  icon: <Monitor />,
  // readAccessPath: 'Master Data.Location Hierarchy - Read',
  // writeAccessPath: 'Master Data.Location Hierarchy - Write',
  readAccessPath: "Master Data.Location Group - Read",
  writeAccessPath: "Master Data.Location Group - Write",
  component: PageComponent.GroupLocationsList,
  children: null,
}

const GroupProductCreateRoutes = {
  id: "Group Locations List",
  path: "/master-data/products/groups/create",
  icon: <Monitor />,
  readAccessPath: "Master Data.Product Group - Read",
  writeAccessPath: "Master Data.Product Group - Write",
  component: PageComponent.ProductGroupCreate,
  children: null,
}

// Autonomous Store

const autonomousStoreRoutes = {
  id: "Autonomous Store",
  path: `/autonomous-store`,
  readAccessPath: "",
  // writeAccessPath: "Autonomous Store",
  icon: <AutonomousStoreIcon />,
  childArray: [
    {
      readAccessPath:
        "Autonomous Store.Smart Device Configuration - Store Config - Read",
    },
    {
      readAccessPath:
        "Autonomous Store.Smart Device Configuration - Turnstiles - Read",
    },
    {
      readAccessPath:
        "Autonomous Store.Smart Device Configuration - Gondola - Read",
    },
    {
      readAccessPath:
        "Autonomous Store.Smart Device Configuration - Planogram - Read",
    },
    {
      readAccessPath:
        "Autonomous Store.Smart Device Configuration - Digit Shelves - Read",
    },
    {
      readAccessPath:
        "Autonomous Store.Smart Device Configuration - Camera - Read",
    },
    {
      readAccessPath: "Autonomous Store.Live Monitoring - Read",
    },
  ],
  children: [
    {
      id: "Device Config",
      name: "Device Config",
      readAccessPath: "",
      icon: "",
      component: null,
      redirect: false,
      path: `/autonomous-store/sdc`,
      childArray: [
        {
          readAccessPath:
            "Autonomous Store.Smart Device Configuration - Store Config - Read",
        },
        {
          readAccessPath:
            "Autonomous Store.Smart Device Configuration - Turnstiles - Read",
        },
        {
          readAccessPath:
            "Autonomous Store.Smart Device Configuration - Gondola - Read",
        },
        {
          readAccessPath:
            "Autonomous Store.Smart Device Configuration - Planogram - Read",
        },
        {
          readAccessPath:
            "Autonomous Store.Smart Device Configuration - Digit Shelves - Read",
        },
        {
          readAccessPath:
            "Autonomous Store.Smart Device Configuration - Camera - Read",
        },
      ],
      children: [
        {
          path: "/autonomous-store/sdc/paymentTerminals",
          name: "Payment Terminals",
          readAccessPath:
            "Autonomous Store.Smart Device Configuration - Store Config - Read",
          writeAccessPath:
            "Autonomous Store.Smart Device Configuration - Store Config - Write",
          component: PageComponent.StoreConfig,
        },

        {
          path: "/autonomous-store/sdc/turnstile",
          name: "Turnstile",
          readAccessPath:
            "Autonomous Store.Smart Device Configuration - Turnstiles - Read",
          writeAccessPath:
            "Autonomous Store.Smart Device Configuration - Turnstiles - Write",
          component: PageComponent.Turnstile,
        },
        {
          path: "/autonomous-store/sdc/gondola",
          name: "Gondola",
          readAccessPath:
            "Autonomous Store.Smart Device Configuration - Gondola - Read",
          writeAccessPath:
            "Autonomous Store.Smart Device Configuration - Gondola - Write",
          component: PageComponent.Gondola,
        },
        {
          path: "/autonomous-store/sdc/smartShelf",
          name: "Smart Shelf",
          readAccessPath:
            "Autonomous Store.Smart Device Configuration - Digit Shelves - Read",
          writeAccessPath:
            "Autonomous Store.Smart Device Configuration - Digit Shelves - Write",
          component: PageComponent.Smartshelf,
        },
        {
          path: "/autonomous-store/sdc/camera",
          name: "Camera",
          readAccessPath:
            "Autonomous Store.Smart Device Configuration - Camera - Read",
          writeAccessPath:
            "Autonomous Store.Smart Device Configuration - Camera - Write",
          component: PageComponent.Camera,
        },
      ],
    },
    {
      path: "/autonomous-store/planogram",
      name: "Planogram",
      //component: PageComponent.SpacePlanningList,
      readAccessPath:
        "Autonomous Store.Smart Device Configuration - Gondola - Read",
      writeAccessPath:
        "Autonomous Store.Smart Device Configuration - Gondola - Write",
      component: PageComponent.Planogram,
    },
    {
      path: "/autonomous-store/livemonitoring",
      id: "Live Monitoring",
      name: "Live Monitoring",
      component: PageComponent.SpacePlanningList,
      readAccessPath: "Autonomous Store.Live Monitoring - Read",
      // writeAccessPath: 'Master Data - Smart Device Configuration.Planogram - Maintenance and Management - Write',
      icon: <PlanogramIcon />,
    },
  ],
}

const EditGondolaRoutes = {
  id: "editGondola",
  path: "/autonomous-store/sdc/gondola/gondolaDetails/:LocationNum/:gondolaId",
  readAccessPath:
    "Autonomous Store.Smart Device Configuration - Gondola - Read",
  writeAccessPath:
    "Autonomous Store.Smart Device Configuration - Gondola - Write",
  component: PageComponent.EditGondolaDetails,
  children: null,
}

const EditCameraRoutes = {
  id: "editCamera",
  path: "/autonomous-store/sdc/camera/cameraDetails/:LocationNum/:cameraId",
  readAccessPath: "Autonomous Store.Smart Device Configuration - Camera - Read",
  writeAccessPath:
    "Autonomous Store.Smart Device Configuration - Camera - Write",
  component: PageComponent.EditCameraDetails,
  children: null,
}

const EditTurnstileRoutes = {
  id: "editTurnstile",
  path:
    "/autonomous-store/sdc/turnstile/turnstileDetails/:LocationNum/:turnstileId",
  readAccessPath:
    "Autonomous Store.Smart Device Configuration - Turnstiles - Read",
  writeAccessPath:
    "Autonomous Store.Smart Device Configuration - Turnstiles - Write",
  component: PageComponent.EditTurnstileDetails,
  children: null,
}

const EditSmartShelfRoutes = {
  id: "editCamera",
  path:
    "/autonomous-store/sdc/smartShelf/smartShelfDetails/:LocationNum/:smartShelfId",
  readAccessPath:
    "Autonomous Store.Smart Device Configuration - Digit Shelves - Read",
  writeAccessPath:
    "Autonomous Store.Smart Device Configuration - Digit Shelves - Write",
  component: PageComponent.EditSmartShelfDetails,
  children: null,
}

const CreateTurnstile = {
  id: "createTurnstile",
  path: "/autonomous-store/sdc/turnstile/create/:LocationNum",
  readAccessPath:
    "Autonomous Store.Smart Device Configuration - Turnstiles - Read",
  writeAccessPath:
    "Autonomous Store.Smart Device Configuration - Turnstiles - Write",
  icon: <Monitor />,
  component: PageComponent.CreateTurnstileDetails,
  children: null,
}

const CreateSmartShelf = {
  id: "createSmartShelf",
  path: "/autonomous-store/sdc/smartShelf/create/:LocationNum",
  readAccessPath:
    "Autonomous Store.Smart Device Configuration - Digit Shelves - Read",
  writeAccessPath:
    "Autonomous Store.Smart Device Configuration - Digit Shelves - Write",
  icon: <Monitor />,
  component: PageComponent.CreateSmartShelfDetails,
  children: null,
}

const CreateCamera = {
  id: "createCamera",
  path: "/autonomous-store/sdc/camera/create/:LocationNum",
  readAccessPath: "Autonomous Store.Smart Device Configuration - Camera - Read",
  writeAccessPath:
    "Autonomous Store.Smart Device Configuration - Camera - Write",
  icon: <Monitor />,
  component: PageComponent.CreateCameraDetails,
  children: null,
}

const CreateGondola = {
  id: "createGondola",
  path: "/autonomous-store/sdc/gondola/create/:LocationNum",
  readAccessPath:
    "Autonomous Store.Smart Device Configuration - Gondola - Read",
  writeAccessPath:
    "Autonomous Store.Smart Device Configuration - Gondola - Write",
  icon: <Monitor />,
  component: PageComponent.CreateGondolaDetails,
  children: null,
}

const maintainPlanoGram = {
  path: "/autonomous-store/livemonitoring/maintainPlanogram/:locationID",
  readAccessPath: "Autonomous Store.Live Monitoring - Read",
  name: "Maintain Planogram",
  icon: <Monitor />,
  component: PageComponent.SpacePlanning,
  children: null,
}

// Transaction Browser

const EditOrderRoutes = {
  id: "Edit Order",
  path: "/transactions/:txnId",
  icon: <Monitor />,
  component: PageComponent.EditOrderDetails,
  children: null,
  readAccessPath: "Transaction Data.Transaction Browser- List, Detail - Read",
  writeAccessPath: "Transaction Data.Transaction Browser- List, Detail - Write",
}

// Transaction  (sass)

const transactionsRoutes = {
  id: "Transactions",
  path: "/tenantTransactions",
  readAccessPath: "Transaction HIL Data.Read",
  writeAccessPath: "Transaction HIL Data.Write",
  icon: <TransactionIcon />,
  component: PageComponent.TenantTransactions,
  children: null,
}

const storeTransactionsRoutes = {
  id: "Transactions",
  path: "/tenantTransactions/:tenantId/:userType",
  readAccessPath: "Transaction HIL Data.Read",
  writeAccessPath: "Transaction HIL Data.Write",
  icon: <TransactionIcon />,
  component: PageComponent.TenantTransactionDetails,
  children: null,
}

const transactionsStoresRoutes = {
  id: "Transactions",
  path: "/tenantTransactions/:tenantId",
  readAccessPath: "Transaction HIL Data.Read",
  writeAccessPath: "Transaction HIL Data.Write",
  icon: <TransactionIcon />,
  component: PageComponent.TenantStores,
  children: null,
}

const EditTenantTransactionsOrder = {
  id: "Edit Order",
  path: "/tenantTransactions/:tenantId/:userType/:txnId",
  readAccessPath: "Transaction HIL Data.Read",
  writeAccessPath: "Transaction HIL Data.Write",
  icon: <Monitor />,
  component: PageComponent.EditTenantTransactionsOrderDetails,
  children: null,
}

// Store User Activities

const employeeDetails = {
  id: "Store User Activities",
  path: "/employeeDetails",
  readAccessPath: "Store User Activities.Store User Activities - Read",
  icon: <UserActivitiesIcon />,
  component: PageComponent.EmployeeDetails,
  children: null,
}

// Tenant

const tenantRoutes = {
  id: "Tenant",
  path: "/tenant",
  readAccessPath: "User Management.Tenant - Read",
  writeAccessPath: "User Management.Tenant - Write",
  icon: <Package />,
  component: PageComponent.Tenant,
  children: null,
}

// Tenant Settings

const tenantSettingsRoutes = {
  id: "Tenant Settings",
  path: "/tenantSettings",
  readAccessPath: "User Management.Tenant Settings - Read",
  writeAccessPath: "User Management.Tenant Settings - Write",
  icon: <Package />,
  component: PageComponent.Tenant,
  children: null,
}

//Sub SAAS Settings
const subSaaSAdminSettingsRoutes = {
  id: "Sub SAAS Settings",
  path: "/subSAASSettings",
  readAccessPath: "User Management.Sub SAAS Settings - Read",
  writeAccessPath: "User Management.Sub SAAS Settings - Write",
  icon: <Package />,
  component: PageComponent.Tenant,
  children: null,
}

const editTenantSettingsRoutes = {
  id: "Edit Tenant",
  path: "/tenantSettings/view/:TenantID/:TenantType?",
  readAccessPath: "User Management.Tenant Settings - Read",
  writeAccessPath: "User Management.Tenant Settings - Write",
  icon: <Monitor />,
  component: PageComponent.TenantCreateEdit,
  children: null,
}

// Sub SAAS View/Edit page
const editSubSAASSettingsRoutes = {
  id: "Edit Tenant",
  path: "/subSAASSettings/view/:TenantID/:TenantType?",
  readAccessPath: "User Management.Sub SAAS Settings - Read",
  writeAccessPath: "User Management.Sub SAAS Settings - Write",
  icon: <Monitor />,
  component: PageComponent.TenantCreateEdit,
  children: null,
}

// Payment Config Create/Edit
const createEditPaymentConfig = {
  id: "Connector Settings",
  path: "/connectorSettings/PaymentGatewayConfig/view/:id?",
  readAccessPath: "Connector Settings.Payment Gateway Config - Read",
  writeAccessPath: "Connector Settings.Payment Gateway Config - Write",
  icon: <Monitor />,
  component: PageComponent.CreateEditPaymentConfig,
  children: null,
}

// Remote Unlock Configuration
const RemoteUnlockConfig = {
  id: "Remote Unlocking",
  path: "/remoteUnlocking",
  readAccessPath: "Store User Activities.Store User Activities - Read",
  icon: <UnlockIcon />,
  component: PageComponent.RemoteUnlockConfig,
  children: null,
}

// User Management

const userRoutes = {
  id: "User Management",
  path: "/user-management",
  name: "User Management",
  icon: <Users />,
  childArray: [
    { readAccessPath: "User Management.Users - Read" },
    { readAccessPath: "User Management.Role Permission - Read" },
  ],
  children: [
    {
      path: "/user-management/users",
      name: "Users",
      readAccessPath: "User Management.Users - Read",
      writeAccessPath: "User Management.Users - Write",
      component: PageComponent.User,
    },
    // {
    //   path: "/user-management/rolePermission",
    //   name: "Role Permissions",
    //   readAccessPath: "User Management.Role Permission - Read",
    //   writeAccessPath: "User Management.Role Permission - Write",
    //   component: PageComponent.RolePermission,
    // },
    {
      id: "Roles & Permission",
      path: "/user-management/rolePermission",
      name: "Roles & Permission",
      readAccessPath: "User Management.Role Permission - Read",
      writeAccessPath: "User Management.Role Permission - Write",
      component: PageComponent.Permission,
    },
  ],
  component: null,
}

const rolePermissionRoutes = {
  id: "Role Permission Details",
  path:
    "/user-management/rolePermission/rolesPermissionDetails/:tenantId?/:tenantName?/:roleId?/:isDefault?",
  readAccessPath: "User Management.Role Permission - Read",
  writeAccessPath: "User Management.Role Permission - Write",
  icon: <Monitor />,
  component: PageComponent.RolePermissionDetails,
  children: null,
}

const createUserRoutes = {
  id: "Create User",
  path: "/user-management/users/create",
  readAccessPath: "User Management.Users - Read",
  writeAccessPath: "User Management.Users - Write",
  icon: <Monitor />,
  component: PageComponent.CreateEditUser,
  children: null,
}

const createVendorsRoutes = {
  id: "Create User",
  path: "/master-data/Storage/Vendors/CreateVendor/:vendorId?/",
  readAccessPath: "Master Data.Storage Unit - Read",
  writeAccessPath: "Master Data.Storage Unit - Write",
  icon: <Monitor />,
  component: PageComponent.createVendors,
  children: null,
}

const updateVendorsRoutes = {
  id: "Update User",
  path: "/master-data/Storage/Vendors/UpdateVendor/:vendorId?/:vendorName?/",
  readAccessPath: "Master Data.Storage Unit - Read",
  writeAccessPath: "Master Data.Storage Unit - Write",
  icon: <Monitor />,
  component: PageComponent.updateVendors,
  children: null,
}

const createTenantRoutes = {
  id: "Create Tenant",
  path: "/tenant/create",
  readAccessPath: "User Management.Tenant - Read",
  writeAccessPath: "User Management.Tenant - Write",
  icon: <Monitor />,
  component: PageComponent.TenantCreateEdit,
  children: null,
}

const editTenantRoutes = {
  id: "Edit Tenant",
  path: "/tenant/view/:TenantID/:TenantType?",
  readAccessPath: "User Management.Tenant - Read",
  writeAccessPath: "User Management.Tenant - Write",
  icon: <Monitor />,
  component: PageComponent.TenantCreateEdit,
  children: null,
}

const editUserRoutes = {
  id: "Edit User",
  path: "/user-management/users/view/:userID",
  readAccessPath: "User Management.Users - Read",
  writeAccessPath: "User Management.Users - Write",
  icon: <Monitor />,
  component: PageComponent.CreateEditUser,
  children: null,
}

// Tax Configuration
const configureRoutes = {
  id: "Tax Configuration",
  path: "/configuration",
  name: "Tax Configuration",
  icon: <TaxConfigurationIcon />,
  readAccessPath: "Tax Configuration.Tax Provider - Read",
  children: [
    {
      path: "/configuration/taxProvider",
      name: "Tax Provider",
      readAccessPath: "Tax Configuration.Tax Provider - Read",
      writeAccessPath: "Tax Configuration.Tax Provider - Write",
      component: PageComponent.TaxProvider,
    },
  ],
}

const createTaxRoutes = {
  id: "Create Tax",
  path: "/configuration/taxProvider/create",
  readAccessPath: "Tax Configuration.Tax Provider - Read",
  writeAccessPath: "Tax Configuration.Tax Provider - Write",
  icon: <Monitor />,
  component: PageComponent.CreateEditTax,
  children: null,
}
const editUserTaxRoutes = {
  id: "Edit Tax",
  path: "/configuration/taxProvider/view/:taxID",
  readAccessPath: "Tax Configuration.Tax Provider - Read",
  writeAccessPath: "Tax Configuration.Tax Provider - Write",
  icon: <Monitor />,
  component: PageComponent.CreateEditTax,
  children: null,
}

// Log Viewer

const logViewerRoutes = {
  id: "Log Viewer",
  path: "/logviewer",
  name: "Log Viewer",
  icon: <LogViewerIcon />,
  readAccessPath: "Log Viewer",
  writeAccessPath: "Log Viewer",
  childArray: [
    { readAccessPath: "Log Viewer.Audit Log - Read" },
    { readAccessPath: "Log Viewer.Log Viewer - Read" },
  ],
  children: [
    {
      path: "/logviewer/auditLog",
      name: "Audit Logs",
      readAccessPath: "Log Viewer.Audit Log - Read",
      // writeAccessPath: "Log Viewer.Log Viewer - Write",
      component: PageComponent.AuditLog,
    },
    {
      path: "/logviewer/applicationlog",
      name: "Application Logs",
      readAccessPath: "Log Viewer.Log Viewer - Read",
      writeAccessPath: "Log Viewer.Log Viewer - Write",
      component: PageComponent.ApplicationLog,
    },
  ],
}
//Connector settings View
const settingssRoutes = {
  id: "Connector Settings",
  path: "/connectorSettings",
  name: "Connector Settings ",
  icon: <Monitor />,
  childArray: [
    { readAccessPath: "Connector Settings.Payment Gateway Config - Read" },
  ],
  children: [
    {
      path: "/connectorSettings/PaymentGatewayConfig",
      name: "Payment Gateway Config",
      readAccessPath: "Connector Settings.Payment Gateway Config - Read",
      writeAccessPath: "Connector Settings.Payment Gateway Config - Write",
      component: PageComponent.PaymentGatewayConfig,
    },
  ],
  component: null,
}

// Profile Update
const profileUpdateRoutes = {
  id: "Profile Update",
  path: "/profileUpdate/:userID",
  // icon: <Monitor />,
  component: PageComponent.ProfileUpdate,
  readAccessPath: "Dashboard Data.Read",
  writeAccessPath: "Dashboard Data.Read",
  children: null,
}

//Frictionless store

const createDeviceFS = {
  id: "createDeviceFS",
  path: "/frictionless-store/sdc/device-management/create/:LocationNum",
  readAccessPath:
    "Frictionless Store.Smart Device Configuration - Device Management - Read",
  writeAccessPath:
    "Frictionless Store.Smart Device Configuration - Device Management - Write",
  icon: <Monitor />,
  component: PageComponent.CreateDeviceMgmtPage,
  children: null,
}

const editDeviceFS = {
  id: "editDeviceFS",
  path:
    "/frictionless-store/sdc/device-management/device-details/:LocationNum/:deviceId",
  readAccessPath:
    "Frictionless Store.Smart Device Configuration - Device Management - Read",
  writeAccessPath:
    "Frictionless Store.Smart Device Configuration - Device Management - Write",
  icon: <Monitor />,
  component: PageComponent.EditDeviceMgmtPage,
  children: null,
}

const CreateFSEdgeDeviceRoutes = {
  id: "createEdgeDevice",
  path: "/frictionless-store/sdc/edgeDevice/create/:LocationNum",
  readAccessPath:
    "Frictionless Store.Smart Device Configuration - Edge Devices - Read",
  writeAccessPath:
    "Frictionless Store.Smart Device Configuration - Edge Devices - Write",
  icon: <Monitor />,
  component: PageComponent.CreateFSEdgeDeviceDetails,
  children: null,
}

const EditFSEdgeDeviceRoutes = {
  id: "editEdgeDevice",
  path:
    "/frictionless-store/sdc/edgeDevice/edgeDeviceDetails/:LocationNum/:edgeDeviceId",
  readAccessPath:
    "Frictionless Store.Smart Device Configuration - Edge Devices - Read",
  writeAccessPath:
    "Frictionless Store.Smart Device Configuration - Edge Devices - Write",
  icon: <Monitor />,
  component: PageComponent.EditFSEdgeDeviceDetails,
  children: null,
}

const maintainFrictionlessStore = {
  path: "/frictionless-store/planogram/maintainFrictionlessStore/:locationID",
  readAccessPath:
    "Frictionless Store.Planogram - Maintenance and Management - Read	",
  name: "Maintain Frictionless Store",
  icon: <Monitor />,
  component: PageComponent.FrictionlessStore,
  children: null,
}

// Report Generation
// const reportsRoutes = {
//   id: "Report Generation",
//   path: "/reportGeneration",
//   readAccessPath: "Reports.Invoice Report - Read",
//   icon: <Monitor />,
//   component: PageComponent.Reports,
//   children: null,
// }

const reportsRoutes = {
  id: "Reports",
  path: "/reports",
  icon: <Monitor />,
  childArray: [
    { readAccessPath: "Reports.Detailed Transaction Report - Read" },
    { readAccessPath: "Reports.Sales Summary Report - Read" },
  ],
  children: [
    {
      path: "/reports/detailedTransactionReport",
      name: "Detailed Transaction Report",
      component: PageComponent.DetailedTransactionReport,
      readAccessPath: "Reports.Detailed Transaction Report - Read",
    },
    {
      path: "/reports/salesSummaryReport",
      name: "Sales Summary Report",
      component: PageComponent.SalesSummaryReport,
      readAccessPath: "Reports.Sales Summary Report - Read",
    },
    {
      path: "/reports/inventoryReport",
      name: "Inventory Report",
      component: PageComponent.InventoryReport,
      readAccessPath: "Reports.Inventory Report - Read",
    },
    {
      path: "/reports/inventoryLogReport",
      name: "Inventory Log Report",
      component: PageComponent.InventoryLogReport,
      readAccessPath: "Reports.Inventory Report - Read",
    }
  ],
  component: null,
}

// Alerts

const alertRoutes = {
  id: "Alerts",
  path: "/alert",
  icon: <AlertIconKit />,
  childArray: [
    { readAccessPath: "Alerts.New Alert - Read" },
    { readAccessPath: "Alerts.Configuration - Read" },
    { readAccessPath: "Alerts.Monitor - Read" },
  ],
  children: [
    {
      path: "/alert/newAlert",
      name: "Alerts Hub",
      component: PageComponent.NewAlert,
      readAccessPath: "Alerts.New Alert - Read",
      writeAccessPath: "Alerts.New Alert - Write",
    },
    {
      path: "/alert/configuration",
      name: "Configuration",
      component: PageComponent.Alerts,
      readAccessPath: "Alerts.Configuration - Read",
      writeAccessPath: "Alerts.Configuration - Write",
    },
    {
      path: "/alert/monitor",
      name: "Monitor",
      component: PageComponent.Alerts,
      readAccessPath: "Alerts.Monitor - Read",
      writeAccessPath: "Alerts.Monitor - Write",
    },
  ],
  component: null,
}

const alertconfigurationRoutes = {
  path: "/alert/configuration/:tenantId",
  name: "Configuration",
  component: PageComponent.Configuration,
  readAccessPath: "Alerts.Configuration - Read",
  writeAccessPath: "Alerts.Configuration - Write",
}

const alertmonitorRoutes = {
  path: "/alert/monitor/:tenantId/:severity?/:locationId?",
  name: "Monitor",
  component: PageComponent.Monitor,
  readAccessPath: "Alerts.Monitor - Read",
  writeAccessPath: "Alerts.Monitor - Write",
}

// Forget password

const forgetPassword = {
  id: "Forget password page",
  path: "/forgetpassword",
  icon: null,
  component: PageComponent.ForgetPassword,
  children: null,
}

const spacePlanningRoutes = {
  path: "/spacePlanning/planogramList",
  id: "View Monitoring",
  component: PageComponent.SpacePlanningList,
  readAccessPath:
    "Master Data - Smart Device Configuration.Planogram - Maintenance and Management - Read",
  // writeAccessPath: 'Master Data - Smart Device Configuration.Planogram - Maintenance and Management - Write',
  icon: <PlanogramIcon />,
  children: null,
}

const salesOrdersRoutes = {
  id: "Transaction Browser",
  path: "/transactions",
  readAccessPath: "Transaction Data.Transaction Browser- List, Detail - Read",
  writeAccessPath: "Transaction Data.Transaction Browser- List, Detail - Write",
  icon: <TransactionIcon />,
  component: PageComponent.Transactions,
  children: null,
}

const liveMonitoringRoutes = {
  id: "Monitoring",
  path: "/livemonitoring",
  readAccessPath: "Live Monitoring - Read",
  writeAccessPath: "Live Monitoring- List, Detail - Write",
  icon: <EqualizerOutlined />,
  component: null,
  children: [
    {
      id: "Products",
      name: "Products",
      readAccessPath: "",
      writeAccessPath: "",
      icon: "",
      component: PageComponent.MonitoringProducts,
      redirect: false,
      path: "/livemonitoring/products",
    },

    {
      id: "Devices",
      name: "Devices",
      readAccessPath: "",
      writeAccessPath: "",
      icon: "",
      component: null,
      redirect: false,
      path: "/frictionless-store/sdc",
    },

    {
      id: "Incidents",
      name: "Incidents",
      readAccessPath: "",
      writeAccessPath: "",
      icon: "",
      component: null,
      redirect: false,
      path: "/frictionless-store/sdc",
    },
  ],
}

const liveMonitoringProductDetailedScreenRoutes = {
  path: "/livemonitoring/products/product-detailed-page/:id",
  readAccessPath: "Master Data.Product - Read",
  writeAccessPath: "Master Data.Product - Write",
  component: PageComponent.EditLiveMonitoringProduct,
}

const EqipmentDetials = {
  id: "EquipmentDetials",
  path: "/eqipmentList/storeEquipmentDetails",
  icon: <Cpu />,
  component: PageComponent.StoreEquipmentDetails,
  children: null,
}

const equipmentList = {
  id: "EquipmentList",
  path: "/eqipmentList",
  icon: <Cpu />,
  component: PageComponent.EquipmentList,
  children: null,
}

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: PageComponent.SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: PageComponent.SignUp,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: PageComponent.ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: PageComponent.Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: PageComponent.Page500,
    },
    {
      path: "/auth/forgetpassword",
      name: "forget password",
      component: PageComponent.ForgetPassword,
    },
    {
      path: "/auth/403",
      name: "403 Page",
      component: PageComponent.Page403,
    },
  ],
  component: null,
}

const landingRoutes = {
  id: "Landing Page",
  path: "/",
  icon: <Monitor />,
  component: PageComponent.SignIn,
  children: null,
}

const proflieRoutes = {
  id: "Edit Profile",
  path: "/edit-profile",
  icon: <Monitor />,
  component: PageComponent.EditProfile,
  children: null,
}

const settingsRoutes = {
  id: "Settings",
  path: "/settings",
  icon: <Monitor />,
  component: PageComponent.Settings,
  children: null,
}

const helpRoutes = {
  id: "Help",
  path: "/help",
  icon: <Monitor />,
  component: PageComponent.Help,
  children: null,
}
const loginRoutes = {
  id: "Login",
  path: "/help",
  // icon: <Monitor />,
  component: PageComponent.Login,
  children: null,
}

const supportRoutes = {
  id: "Help",
  path: "/support",
  icon: <Monitor />,
  component: PageComponent.Support,
  children: null,
}

const privacyRoutes = {
  id: "Help",
  path: "/privacy",
  icon: <Monitor />,
  component: PageComponent.Privacy,
  children: null,
}

const termsOfServiceRoutes = {
  id: "Help",
  path: "/terms-of-service",
  icon: <Monitor />,
  component: PageComponent.TermsOfService,
  children: null,
}

const statusRoutes = {
  id: "Status",
  path: "/status",
  icon: <Layout />,
  component: PageComponent.Status,
  children: null,
}

//Master Data

const masterDataRoutes = {
  id: "Master Data",
  path: "/master-data",
  readAccessPath: "",
  icon: <List />,
  childArray: [
    { readAccessPath: "Master Data.Location - Read" },
    { readAccessPath: "Master Data.Location - Read" },
    { readAccessPath: "Master Data.Product - Read" },
    { readAccessPath: "Master Data.Inventory - Read" },
  ],
  children: [
    {
      id: "Storage",
      name: "Storage",
      readAccessPath: "",
      icon: "",
      component: null,
      redirect: false,
      path: `/master-data/storage`,
      childArray: [{ readAccessPath: "Master Data.Storage Unit - Read" }],
      children: [
        {
          path: "/master-data/storage/Vendors",
          name: "Vendors",
          readAccessPath: "Master Data.Storage Unit - Read",
          writeAccessPath: "Master Data.Storage Unit - Write",
          component: PageComponent.Vendors,
        },
        {
          path: "/master-data/storage/storageunit",
          name: "Storage Unit",
          readAccessPath: "Master Data.Storage Unit - Read",
          writeAccessPath: "Master Data.Storage Unit - Write",
          component: PageComponent.LocationsProductStorage,
        },
      ],
    },

    {
      path: "/master-data/locations",
      name: "Stores",
      icon: "",
      id: "Stores",
      readAccessPath: "Master Data.Location - Read",
      writeAccessPath: "Master Data.Location - Write",
      component: PageComponent.Locations,
      skipChildArray: true,
      childArray: [
        { readAccessPath: "Master Data.Location - Read" },
        { readAccessPath: "Master Data.Location Group - Read" },
        { readAccessPath: "Master Data.Location Hierarchy - Read" },
        { readAccessPath: "Master Data.Location Hierarchy Mapping - Read" },
      ],
      children: [
        {
          path: "/master-data/locations/groups",
          name: "Groups",
          readAccessPath: "Master Data.Location Group - Read",
          writeAccessPath: "Master Data.Location Group - Write",
          component: masterDataLocationGroups,
        },
        {
          path: "/master-data/locations/hierarchy",
          name: "Hierarchy",
          readAccessPath: "Master Data.Location Hierarchy - Read",
          writeAccessPath: "Master Data.Location Hierarchy - Write",
          component: masterDataLocationHierarchy,
        },
        {
          path: "/master-data/locations/hierarchyMapping",
          name: "Hierarchy Mapping",
          readAccessPath: "Master Data.Location Hierarchy Mapping - Read",
          writeAccessPath: "Master Data.Location Hierarchy Mapping - Write",
          component: masterDataLocationMapping,
        },
      ],
    },
    {
      path: "/master-data/products",
      name: "Products",
      icon: "",
      id: "Products",
      readAccessPath: "Master Data.Product - Read",
      writeAccessPath: "Master Data.Product - Write",
      component: PageComponent.Products,
      skipChildArray: true,
      childArray: [
        { readAccessPath: "Master Data.Product - Read" },
        { readAccessPath: "Master Data.Product Group - Read" },
        { readAccessPath: "Master Data.Product Category - Read" },
        { readAccessPath: "Master Data.Product Category Mapping - Read" },
      ],
      children: [
        {
          path: "/master-data/products/groups",
          name: "Groups",
          readAccessPath: "Master Data.Product Group - Read",
          writeAccessPath: "Master Data.Product Group - Write",
          component: masterDataproductsGroups,
        },
        {
          path: "/master-data/products/hierarchy",
          name: "Category",
          readAccessPath: "Master Data.Product Category - Read",
          writeAccessPath: "Master Data.Product Category - Write",
          component: masterDataproductsHierarchy,
        },
        {
          path: "/master-data/products/productMapping",
          name: "Category Mapping",
          readAccessPath: "Master Data.Product Category Mapping - Read",
          writeAccessPath: "Master Data.Product Category Mapping - Write",
          component: masterDataproductsMapping,
        },
        {
          path: "/master-data/products/attribute",
          name: "Attributes",
          readAccessPath: "Master Data.Product Attributes - Read",
          writeAccessPath: "Master Data.Product Attributes - Write",
          component: masterDataproductsAttribute,
        },
      ],
    },
    // {
    //   id: "Inventory",
    //   name: "Inventory",
    //   // readAccessPath: "Master Data.Inventory - Read",
    //   //     writeAccessPath: "Master Data.Inventory - Write",
    //   readAccessPath: "",
    //   // writeAccessPath: "Autonomous Store",
    //   icon: <PlanogramIcon />,
    //   component: null,
    //   redirect: false,
    //   path: `/master-data/inventory`,
    //   childArray: [
    //     { readAccessPath: "Master Data.Inventory - Read" },
    //     { readAccessPath: "Master Data.Inventory Adjustments - Read" },
    //   ],
    //   children: [
    //     {
    //       path: "/master-data/inventory/store/inventory",
    //       name: "Store Inventory",
    //       readAccessPath: "Master Data.Inventory - Read",
    //       writeAccessPath: "Master Data.Inventory - Write",
    //       component: PageComponent.Inventory,
    //     },
    //     {
    //       path: "/master-data/inventory/store/adjustments",
    //       name: "Store Adjustments",
    //       readAccessPath: "Master Data.Inventory Adjustments - Read",
    //       writeAccessPath: "Master Data.Inventory Adjustments - Write",
    //       component: PageComponent.InventoryAdjustments,
    //     },
    //     {
    //       path: "/master-data/inventory/storage/inventory",
    //       name: "Storage Inventory",
    //       readAccessPath: "Master Data.Inventory - Read",
    //       writeAccessPath: "Master Data.Inventory - Write",
    //       component: PageComponent.InventoryStorage,
    //     },
    //     {
    //       path: "/master-data/inventory/storage/adjustments",
    //       name: "Storage Adjustments",
    //       readAccessPath: "Master Data.Inventory Adjustments - Read",
    //       writeAccessPath: "Master Data.Inventory Adjustments - Write",
    //       component: PageComponent.InventoryStorageAdjustments,
    //     },
    //   ],
    // },
    // {
    //   path: "/master-data/inventory",
    //   id: "Inventory",
    //   name: "Inventory",
    //   component: PageComponent.Inventory,
    //   readAccessPath: "Master Data.Inventory - Read",
    //   writeAccessPath: "Master Data.Inventory - Write",
    //   skipChildArray: true,
    //   icon: <PlanogramIcon />,
    //   childArray: [
    //     { readAccessPath: "Master Data.Inventory - Read" },
    //     { readAccessPath: "Master Data.Inventory Adjustments - Read" },
    //   ],
    //   children: [
    //     {
    //       path: "/master-data/inventory/adjustments",
    //       name: "Adjustments",
    //       readAccessPath: "Master Data.Inventory Adjustments - Read",
    //       writeAccessPath: "Master Data.Inventory Adjustments - Write",
    //       component: masterDataInventoryAdjustments,
    //     },
    //   ],
    // },
  ],
  component: null,
}


const inventoryDataRoutes = {
  id: "Inventory Mgmt",
  path: "/inventory",
  readAccessPath: "",
  icon: <BarChartIcon />,
  childArray: [
    { readAccessPath: "Master Data.Inventory - Read" }
  ],
  children: [
    {
      id: "Storage",
      name: "StorageInventory",
      readAccessPath: "",
      icon: "",
      component: null,
      redirect: false,
      path: `/inventory/storage`,
      childArray: [{ readAccessPath: "Master Data.Storage Unit - Read" }],
      children: [
        {
          path: "/inventory/storage/inventory",
          name: "INV Snapshot",
          readAccessPath: "Master Data.Inventory - Read",
          writeAccessPath: "Master Data.Inventory - Write",
          component: PageComponent.InventoryStorage,
        },
        {
          path: "/inventory/storage/adjustments",
          name: "Adjustments",
          readAccessPath: "Master Data.Inventory Adjustments - Read",
          writeAccessPath: "Master Data.Inventory Adjustments - Write",
          component: PageComponent.InventoryStorageAdjustments,
        },
      ],
    },

    {
      id: "Store",
      name: "StoreInventory",
      readAccessPath: "/inventory/store/inventory",
      icon: "",
      component: null,
      redirect: false,
      path: `/inventory/store`,
      childArray: [{ readAccessPath: "Master Data.Storage Unit - Read" }],
      children: [
        {
          path: "/inventory/store/inventory",
          name: "INV Snapshot",
          readAccessPath: "Master Data.Inventory - Read",
          writeAccessPath: "Master Data.Inventory - Write",
          component: PageComponent.Inventory,
        },
        {
          path: "/inventory/store/adjustments",
          name: "Adjustments",
          readAccessPath: "Master Data.Inventory Adjustments - Read",
          writeAccessPath: "Master Data.Inventory Adjustments - Write",
          component: PageComponent.InventoryAdjustments,
        },
        {
          path: "/inventory/store/cycleCounts",
          name: "Cycle Counts",
          readAccessPath: "Master Data.Inventory Adjustments - Read",
          writeAccessPath: "Master Data.Inventory Adjustments - Write",
          component: PageComponent.InventoryCycleCounts,
        },
        {
          path: "/inventory/store/inventoryLogs",
          name: "Inventory Logs",
          readAccessPath: "Master Data.Inventory Adjustments - Read",
          writeAccessPath: "Master Data.Inventory Adjustments - Write",
          component: PageComponent.InventoryLogs,
        },
      ],
    }]
}


const dashboardLayout = [
  oldDashboardsRoutes,
  dashboardsRoutes,
  planogramDetailRoutes,
  spacePlanningRoutes,
  //FrictionlessStoreRoutes,
  contentRoutes,
  // forgetPassword,
  // smartDeviceConfigRoutes,
  masterDataRoutes,
  inventoryDataRoutes,
  salesOrdersRoutes,
  transactionsRoutes,
  transactionsStoresRoutes,
  EditTenantTransactionsOrder,
  storeTransactionsRoutes,
  liveMonitoringRoutes,
  userRoutes,
  proflieRoutes,
  helpRoutes,
  loginRoutes,
  settingsRoutes,
  privacyRoutes,
  supportRoutes,
  termsOfServiceRoutes,
  createUpdateStorageRoutes,
  createLocationRoutes,
  createPosRoutes,
  rolePermissionRoutes,
  liveMonitoringProductDetailedScreenRoutes,
  masterDataLocationDetailedScreenRoutes,
  masterDataLocationGroupDetailedScreenRoutes,
  masterDataCustomerDetailedScreenRoutes,
  masterDataProductDetailedScreenRoutes,
  masterDataProductGroupDetailedScreenRoutes,
  masterDataPriceDetailedScreenRoutes,
  masterDataInventoryDetailedScreenRoutes,
  masterDataAddNewAddressScreenRoutes,
  statusRoutes,
  createCustomerRoutes,
  createProductRoutes,
  editProductRoutes,
  createInventoryRoutes,
  createPriceRoutes,
  GroupLocationsListRoutes,
  GroupProductCreateRoutes,
  createProductGroup,
  createEquipmentRoutes,
  EditOrderRoutes,
  CreateTurnstile,
  CreateSmartShelf,
  CreateCamera,
  CreateGondola,
  EditTurnstileRoutes,
  EditGondolaRoutes,
  EditCameraRoutes,
  EditSmartShelfRoutes,
  equipmentList,
  EqipmentDetials,
  masterDataproductsGroups,
  masterDataproductsHierarchy,
  masterDataproductsMapping,
  masterDataLocationGroups,
  masterDataLocationHierarchy,
  masterDataLocationMapping,
  // masterDataLocationPos,
  masterDataPosDetailedScreenRoutes,
  masterDataproductsAttribute,
  masterDataInventoryAdjustments,
  maintainPlanoGram,
  maintainFrictionlessStore,
  tenantRoutes,
  editTenantSettingsRoutes,
  editSubSAASSettingsRoutes,
  createEditPaymentConfig,
  tenantSettingsRoutes,
  subSaaSAdminSettingsRoutes,
  // CreateFSCamera,
  CreateFSEdgeDeviceRoutes,
  EditFSEdgeDeviceRoutes,
  // EditFSCameraRoutes,
  createVendorsRoutes,
  updateVendorsRoutes,
  createUserRoutes,
  createTaxRoutes,
  editUserTaxRoutes,
  editUserRoutes,
  createTenantRoutes,
  editTenantRoutes,
  createDeviceFS,
  editDeviceFS,
  profileUpdateRoutes,
  configureRoutes,
  logViewerRoutes,
  alertconfigurationRoutes,
  alertmonitorRoutes,
  settingssRoutes,
  inventoryDetails,
  inventoryStorageDetails,
  adjustmentDetails,
  adjustmentStorageDetails,
  invetoryAdjustmentDetails,
  invetoryAdjustmentStorageDetails,
  invetoryCycleCountsDetails,
]

// oldDashboardsRoutes,
const sidebarRoute = [
  dashboardsRoutes,
  contentRoutes,
  masterDataRoutes,
  inventoryDataRoutes,
  autonomousStoreRoutes,
  // smartDeviceConfigRoutes,  commented
  //FrictionlessStoreRoutes, commented
  // ReportingRoutes, commented
  // spacePlanningRoutes, commented
  salesOrdersRoutes,
  transactionsRoutes,
  employeeDetails,
  reportsRoutes,
  alertRoutes,
  // liveMonitoringRoutes,
  // supportAndTrainingRoutes,
  userRoutes,
  configureRoutes,
  logViewerRoutes,
  tenantRoutes,
  tenantSettingsRoutes,
  subSaaSAdminSettingsRoutes,
  settingsRoutes,
  settingssRoutes,
  //RemoteUnlockConfig
]

export { dashboardLayout, authRoutes, landingRoutes, sidebarRoute }
